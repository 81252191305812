export const notesPlaceholders: string[] = [
  "\"I’ve recently completed an online course in digital marketing and am actively looking for opportunities in the field. I'm especially interested in SEO and content creation roles.\"",
  '"Although I don’t have direct work experience in tech, I’ve been self-learning coding for about six months. I’m eager to transition into a software development career."',
  '"I’m passionate about environmental sustainability and would love to find a career that combines my skills in business management and my interest in green technologies. I’m also open to opportunities in project management."',
  '"I’m currently working part-time as a freelance graphic designer but am looking for full-time opportunities in a creative environment."',
  '"I’m bilingual in English and Spanish, and I’ve been thinking about pursuing a career in international business. My main interests are in supply chain management and global logistics."',
  '"I’ve been out of the workforce for a few years while taking care of family, but I’m now ready to re-enter the job market. I’m interested in remote customer service or project management roles."',
  '"I recently graduated with a degree in psychology and am considering a career in human resources."',
  '"I’m really passionate about data science and machine learning. I’m working on personal projects, but I’m struggling to find a full-time position that aligns with my skills."',
  '"I’ve been working in marketing for 5 years but feel like I’m stuck in a rut. I’d love to transition into a leadership role."',
  '"I’m currently balancing a full-time job and raising young children, so I’m looking for flexible career options."',
  '"I’ve been working in retail for several years but feel ready for a new challenge. I’m interested in going into tech but lack formal education in that field. I’m looking for advice on how to gain the necessary skills and get started in tech-related roles."',
]
