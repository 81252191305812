import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'

type multiRadioInputProps = {
    label: string
    items: string[]
    checked: string[]
    onCheck: (_v: string) => void
    max: number
}

const MultiRadioInput: React.FC<multiRadioInputProps> = ({
  label,
  items,
  checked,
  onCheck,
  max
}): ReactElement => {
  const handleOnChangeEvent = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = event.target.value

    if (checked.includes(value)) {
      onCheck(value)
      return
    }

    if (checked.length < max) {
      onCheck(value)
    }
  }

  return (
    <div className="flex flex-col">
      <p className="text-[12px] lg:text-[14px]">{label}</p>

      <div className="mt-2"/>

      {items.map((item: string, index: number): ReactElement => (
        <div className="flex items-center mb-1 last:mb-0 pl-2" key={index} id="group">
          <input
            type="checkbox"
            name="group"
            className="radio-input"
            value={item}
            checked={checked.includes(item)}
            onChange={handleOnChangeEvent}
          />

          <div className="ml-2"/>

          <label
            className="text-[12px] lg:text-[14px] text-[#575757]"
          >
            {item}
          </label>
        </div>
      ))}

      <div className="mt-2"/>

      <p className="text-[10px] lg:text-[12px] text-[#575757] text-right">
        {`${checked.length}/${max}`}
      </p>
    </div>
  )
}

MultiRadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  checked: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  max: PropTypes.number.isRequired
}

export default MultiRadioInput
