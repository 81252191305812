const validationErrorFieldFinderRegExp: RegExp = /"([^"]*)"/

export const findFieldInValidationErrorMessage = (message: string): string => {
  const match = message.match(validationErrorFieldFinderRegExp)
  if (!match) {
    return ''
  }

  return match[0]
}

export const suggestionDemandFieldStyles = (value: string): string => {
  let colorStyles: string

  switch (value.toLowerCase()) {
  case 'low':
    colorStyles = 'text-[#f87171] bg-[#f8717120]'
    break
  case 'medium':
    colorStyles = 'text-[#fb923c] bg-[#fb923c20]'
    break
  case 'high':
    colorStyles = 'text-[#0ea5e9] bg-[#0ea5e920]'
    break
  case 'very high':
    colorStyles = 'text-[#34d399] bg-[#34d39920]'
    break
  default:
    colorStyles = 'text-[#6b7280] bg-[#6b728020]'
    break
  }

  return `w-fit p-1 rounded-[6px] ${colorStyles}`
}

export const suggestionMatchRatingColor = (value: number): string => {
  let color: string = '#ef4444'

  switch (true) {
  case value >= 50 && value < 65:
    color = '#f97316'
    break
  case value >= 65 && value < 80:
    color = '#facc15'
    break
  case value >= 80 && value < 90:
    color = '#84cc16'
    break
  case value >= 90:
    color = '#22c55e'
    break
  }

  return color
}

export const constructBaseURL = (): string =>
  window.location.origin || `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`

