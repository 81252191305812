import React, { ReactElement } from 'react'
import { ReactComponent as Logo } from '../media/logo.svg'
import { ReactComponent as GitHubLogo } from '../media/github-logo.svg'
import { ReactComponent as TikTokLogo } from '../media/tiktok-logo.svg'
import { helloEmail } from '../constants/constants'

const Footer = (): ReactElement => (
  <footer className="bg-[#f8f8f8] border-t-[1px] border-[#e4e0e1] flex flex-col justify-center items-center w-full">
    <div className="w-full max-w-[1000px] px-4 lg:px-0">
      <div className="mt-4 sm:mt-0"/>

      <div className="box-content flex flex-col sm:flex-row justify-between items-center sm:max-w-[1000px] sm:h-[45px] sm:py-[2.5rem] sm:pb-[0.25rem]">
        <div className="flex items-center">
          <Logo className="h-[20px] w-[20px] sm:h-[25px] sm:w-[25px]" />

          <div className="ml-1"/>

          <p className="text-[12px] font-[500]">
            {'Few Steps Closer'}
          </p>
        </div>

        <div className="mt-4 sm:mt-0"/>

        <p className="text-[11px]">{helloEmail}</p>

        <div className="mt-4 sm:mt-0"/>

        <div className="flex gap-2">
          <a
            href="https://www.tiktok.com/@fewstepscloser_app"
            target="_blank"
            rel="noopener noreferrer"
            className="group"
          >
            <TikTokLogo className="w-[18px] h-[18px] group-hover:fill-[#0035FF]" />
          </a>

          <a
            href="https://github.com/few-steps-closer"
            target="_blank"
            rel="noopener noreferrer"
            className="group"
          >
            <GitHubLogo className="w-[18px] h-[18px] group-hover:fill-[#0035FF]"/>
          </a>
        </div>
      </div>

      <div className="sm:border-t-[1px] sm:border-[#e4e0e1] flex justify-center sm:justify-start">
        <p className="text-[#575757] py-2 text-[10px] font-[300]">
          {`© ${new Date().getFullYear()} Few Steps Closer. All rights reserved.`}
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
