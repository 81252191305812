import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import React, { ReactElement } from 'react'
import NotFound from '../pages/NotFound'
import SignIn from '../pages/SignIn'
import {
  authGoogleCallback,
  bookmarkedSuggestionsRoute,
  createSuggestionsFormRoute,
  homeRoute,
  signInRoute,
  signUpRoute,
  threadShareRoute,
  threadSuggestionsRoute
} from './routes'
import SignUp from '../pages/SignUp'
import CreateSuggestionsForm from './CreateSuggestionsForm'
import { useAuthorization } from '../context/authorization.context'
import AuthGoogleCallback from './AuthGoogleCallback'
import Layout from './Layout'
import ThreadSuggestions from '../pages/ThreadSuggestions'
import BookmarkedSuggestions from '../pages/BookmarkedSuggestions'
import ThreadShare from '../pages/ThreadShare'

const Router = (): ReactElement => {
  const { value } = useAuthorization()

  return (
    <BrowserRouter>
      <Routes>
        <Route element={value ? <Layout /> : <Navigate to={signInRoute} />}>
          <Route
            path={bookmarkedSuggestionsRoute}
            element={<BookmarkedSuggestions />}
          />

          <Route
            path={createSuggestionsFormRoute}
            element={<CreateSuggestionsForm />}
          />

          <Route
            path={threadSuggestionsRoute}
            element={<ThreadSuggestions />}
          />
        </Route>

        <Route
          element={
            value ?
              <Navigate to={createSuggestionsFormRoute} /> :
              <Outlet />
          }
        >
          <Route
            path={signInRoute}
            element={<SignIn />}
          />

          <Route
            path={signUpRoute}
            element={<SignUp />}
          />

          <Route
            path={authGoogleCallback}
            element={<AuthGoogleCallback />}
          />
        </Route>

        <Route
          path={homeRoute}
          element={<Home />}
        />

        <Route
          path={threadShareRoute}
          element={<ThreadShare />}
        />

        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
