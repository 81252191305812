import React, { MouseEventHandler, ReactElement, useRef, useState } from 'react'
import { Thread as TThread, UpdateThread } from '../types/types'
import { constructThreadsSuggestionsRoute } from './routes'
import { Location, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { ReactComponent as ShareIcon } from '../media/share-icon.svg'
import { ReactComponent as EditIcon } from '../media/edit-icon.svg'
import { ReactComponent as DotsIcon } from '../media/dots-icon.svg'
import { ReactComponent as TrashCanIcon } from '../media/trash-can-icon.svg'
import { usePopper } from '../context/popper.context'
import { useNotify } from '../context/notify.context'
import { useModal } from '../context/modal.context'
import { copyToClipboard } from '../helpers/helpers'

type threadProps = {
  value: TThread
  shareThread: (_id: string) => Promise<string>
  updateThread: (_id: string, _update: UpdateThread) => void
  deleteThread: (_id: string) => void
}

const Thread: React.FC<threadProps> = ({ value, shareThread, updateThread, deleteThread }) => {
  const location: Location = useLocation()
  const { idPopperVisible, setPopper, hidePopper } = usePopper()
  const { notify } = useNotify()
  const { show: showModal } = useModal()

  const popperButtonRef: React.MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement>(null)
  const newTitleInputRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null)

  const [isThreadHovered, setIsThreadHovered] = useState<boolean>(false)

  const isThreadActive = (threadId: string): boolean => location.pathname.includes(threadId) || isThreadHovered || idPopperVisible(value.id)

  const popperButtonClick: MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()

    if (popperButtonRef) {
      setPopper(value.id, popperButtonRef, threadPopper())
    }
  }

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false)
  const [newTitle, setNewTitle] = useState<string>(value.title)

  const validateNewTitle = (): [boolean, string] => {
    if (newTitle.length === 0) {
      return [false, 'Title cannot be empty']
    }

    if (newTitle.length > 50) {
      return [false, 'Title cannot have more than 50 characters']
    }

    return [true, '']
  }

  const handleTitleUpdate = (): void => {
    const [ok, message] = validateNewTitle()
    if (!ok) {
      notify(message, 'error')
      newTitleInputRef.current?.focus()
      setIsEditingTitle(false)
      setNewTitle(value.title)
      return
    }

    if (newTitle !== value.title) {
      updateThread(value.id, {
        title: newTitle
      })
    }

    setIsEditingTitle(false)
  }

  const handleTitleInputBlur = (_event: React.FocusEvent<HTMLInputElement>): void => handleTitleUpdate()

  const handleTitleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== 'Enter') {
      return
    }

    handleTitleUpdate()
  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewTitle(event.target.value)
  }

  const shareThreadButtonClick = async (): Promise<void> => {
    const shareURL: string = await shareThread(value.id)

    showModal({
      title: 'Copy your shareable link',
      subtitle: 'Your shareable link was successfully created:',
      description: shareURL,
      submitButtonTitle: 'Copy to clipboard',
      submitButtonClick: async (): Promise<void> => {
        await copyToClipboard(shareURL)
        showModal(null)
        notify('Shareable link successfully copied.', 'success')
      },
    })

    hidePopper()
  }

  const renameThreadButtonClick = (): void => {
    setIsEditingTitle(true)
    hidePopper()
  }

  const deleteThreadButtonClick = (): void => {
    showModal({
      title: 'Remove suggestions',
      subtitle: `You are about to delete "${value.title}".`,
      description: 'Once you click the delete button, this action cannot be undone. Are you sure you want to proceed?',
      deleteButtonClick: (): void => {
        deleteThread(value.id)
        showModal(null)
      },
    })
    hidePopper()
  }

  const threadHeader = (): ReactElement => (
    <div className="flex items-center justify-center h-[24px]">
      <div className="grow overflow-hidden h-full relative whitespace-nowrap">
        {isEditingTitle ? (
          <input
            ref={newTitleInputRef}
            type="text"
            className="flex items-center w-full h-full bg-transparent text-[12px] lg:text-[13px] font-[500] focus:outline-[#0035ff]"
            value={newTitle}
            onChange={handleTitleChange}
            onBlur={handleTitleInputBlur}
            onKeyDown={handleTitleInputKeyDown}
            autoFocus
          />
        ) : (
          <>
            <p
              className={
                classNames(
                  'flex items-center w-full h-full bg-transparent text-[12px] lg:text-[13px] font-[500]',
                  { 'text-[#0035ff]': isThreadActive(value.id) }
                )
              }
            >
              {value.title}
            </p>

            <div
              className={
                classNames(
                  'w-8 h-full top-0 right-[-0.5rem] absolute group-hover:bg-gradient-to-r group-hover:from-transparent group-hover:via-[#e8eaff]/85 group-hover:to-[#e8eaff] group-hover:md:via-[#e2e3f8]/85 group-hover:md:to-[#e2e3f8]',
                  isThreadActive(value.id) ? 'bg-gradient-to-r from-transparent via-[#e8eaff]/85 to-[#e8eaff] md:via-[#e2e3f8]/85 md:to-[#e2e3f8]' : 'bg-gradient-to-r from-transparent via-white/85 to-white md:via-[#f8f8f8]/85 md:to-[#f8f8f8]',
                )
              }
            />
          </>
        )}
      </div>

      {isThreadActive(value.id) && (
        <button
          className="ml-1"
          ref={popperButtonRef}
          onClick={popperButtonClick}
        >
          <DotsIcon className="w-[24px] h-[24px] stroke-[#666666] hover:stroke-black"/>
        </button>
      )}
    </div>
  )

  const threadPopper = (): ReactElement => (
    <div className="flex flex-col">
      <button
        className="flex items-center p-2 rounded-[8px] hover:bg-[#f8f8f8]"
        onClick={shareThreadButtonClick}
      >
        <ShareIcon className="w-[18px] h-[18px] lg:w-[20px] lg:h-[20px]"/>

        <div className="ml-2"/>

        <p className="text-[12px] lg:text-[13px]">{'Share'}</p>
      </button>

      <button
        className="flex items-center p-2 rounded-[8px] hover:bg-[#f8f8f8]"
        onClick={renameThreadButtonClick}
      >
        <EditIcon className="w-[18px] h-[18px] lg:w-[20px] lg:h-[20px]"/>

        <div className="ml-2"/>

        <p className="text-[12px] lg:text-[13px]">{'Rename'}</p>
      </button>

      <button
        className="flex items-center p-2 rounded-[8px] hover:bg-[#f8f8f8]"
        onClick={deleteThreadButtonClick}
      >
        <TrashCanIcon className="w-[18px] h-[18px] lg:w-[20px] lg:h-[20px] text-red-500 stroke-red-500 fill-white"/>

        <div className="ml-2"/>

        <p className="text-[12px] lg:text-[13px] text-red-500">
          {'Delete'}
        </p>
      </button>
    </div>
  )

  return (
    <div
      className="flex relative overflow-hidden"
      onMouseEnter={(): void => setIsThreadHovered(true)}
      onMouseLeave={(): void => setIsThreadHovered(false)}
    >
      <a
        href={constructThreadsSuggestionsRoute(value.id)}
        className={
          classNames(
            'block w-full p-2 rounded-[10px] cursor-pointer whitespace-nowrap overflow-hidden hover:bg-[#0035ff] hover:bg-opacity-10 group',
            {
              'bg-[#0035ff] bg-opacity-10': isThreadActive(value.id)
            }
          )
        }
        onClick={(event): false | void => location.pathname.includes(value.id) && event.preventDefault()}
        onDoubleClick={(): void => setIsEditingTitle(true)}
      >
        {threadHeader()}

        <div className="pl-2">
          {value.suggestions.map((suggestion: string, index: number) => (
            <div key={index} className="relative overflow-hidden">
              <p className="text-[12px] lg:text-[13px] text-[#575757] relative">
                {suggestion}
              </p>

              <div
                className={
                  classNames(
                    'w-8 h-full top-0 right-[-0.5rem] absolute group-hover:bg-gradient-to-r group-hover:from-transparent group-hover:via-[#e8eaff]/85 group-hover:to-[#e8eaff] group-hover:md:via-[#e2e3f8]/85 group-hover:md:to-[#e2e3f8]',
                    isThreadActive(value.id) ? 'bg-gradient-to-r from-transparent via-[#e8eaff]/85 to-[#e8eaff] md:via-[#e2e3f8]/85 md:to-[#e2e3f8]' : 'bg-gradient-to-r from-transparent via-white/85 to-white md:via-[#f8f8f8]/85 md:to-[#f8f8f8]',
                  )
                }
              />
            </div>
          ))}
        </div>
      </a>
    </div>
  )
}

Thread.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    suggestions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    created_at: PropTypes.string.isRequired
  }).isRequired,
  updateThread: PropTypes.func.isRequired,
  deleteThread: PropTypes.func.isRequired
}

export default Thread
