import React, { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import FormStep from './FormStep'
import { ThreadWithSuggestions, Limits, CreateSuggestions } from '../types/types'
import FormStepsBar from './FormStepsBar'
import { AuthorizationContext, useAuthorization } from '../context/authorization.context'
import HttpClient from '../services/http/client'
import { NavigateFunction, useNavigate, useOutletContext } from 'react-router-dom'
import { constructThreadsSuggestionsRoute } from './routes'
import { ReactComponent as ArrowLeftIcon } from '../media/arrow-left-icon.svg'
import { ReactComponent as TryAgainIcon } from '../media/try-again-icon.svg'
import { useNotify } from '../context/notify.context'
import { QueryClient, useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query'
import { helloEmail } from '../constants/constants'
import { BadRequestError, TooManyRequestsError } from '../services/http/errors'
import QuotesBlock from './QuotesBlock'
import Client from '../services/http/client'
import classNames from 'classnames'
import RadioInput from './RadioInput'
import ScaleInput from './ScaleInput'
import SliderInput from './SliderInput'
import TextArea from './TextArea'
import CategoriesSelectInput from './CategoriesSelectInput'
import MultiRadioInput from './MultiRadioInput'
import { notesPlaceholders } from '../constants/placeholders'

type createSuggestionsMutation = { userId: string, create: CreateSuggestions }

const CreateSuggestionsForm = (): ReactElement => {
  const outletContext = useOutletContext<{ scrollToTop: () => void }>()
  const authorizationContext: AuthorizationContext = useAuthorization()
  const navigate: NavigateFunction = useNavigate()
  const { notify } = useNotify()
  const queryClient: QueryClient = useQueryClient()

  const lastFormStepIndex: number = 4

  const client: HttpClient = useMemo((): Client => new HttpClient(
    process.env.REACT_APP_HTTP_SERVER_ADDRESS ?? 'http://127.0.0.1:8080',
    authorizationContext,
  ), [authorizationContext])

  const [formValue, setFormValue] = useState<CreateSuggestions>({
    hobbies: [],
    movie_genre: '',
    day_off_preferences: '',
    desired_problems: [
      Math.floor(Math.random() * 11),
      Math.floor(Math.random() * 11),
      Math.floor(Math.random() * 11),
      Math.floor(Math.random() * 11)
    ],
    tool_choice: '',
    favorite_class: '',
    previously_built: '',
    work_independence_preferences: '',
    dream_workspace: '',
    favorite_work: [
      Math.floor(Math.random() * 11),
      Math.floor(Math.random() * 11),
      Math.floor(Math.random() * 11),
      Math.floor(Math.random() * 11)
    ],
    perks_preferences: [],
    feedback: '',
    preferred_learning_style: '',
    role_on_project: '',
    interesting_topics: [],
    theme_song: '',
    time_machine: '',
    management_experience: '',
    ideal_career_alignment: '',
    enjoyable_tasks: [
      Math.floor(Math.random() * 11),
      Math.floor(Math.random() * 11),
      Math.floor(Math.random() * 11),
      Math.floor(Math.random() * 11)
    ],
    superpower_scales: [0, 0, 0, 0],
    group_role_preference_scales: [0, 0, 0],
    security_preference_scales: [0, 0, 0],
    creative_preferences_scales: [0, 0, 0],
    work_pace_scales: [0],
    innovative_creative_preferences_scales: [0, 0, 0, 0],
    confidence_learning_new_skills: '',
    notes: '',
  })
  const [currentFormStepIndex, setCurrentFormStepIndex] = useState<number>(1)

  useEffect((): void => {
    if (outletContext && outletContext?.scrollToTop) {
      outletContext.scrollToTop()
    }
  }, [outletContext, currentFormStepIndex])

  const {
    data: limits,
    isLoading: limitsLoading,
  } = useQuery(
    {
      queryKey: ['limits', authorizationContext.value?.user_id],
      queryFn: async (): Promise<Limits> =>
        client.userLimits(authorizationContext.value?.user_id ?? ''),
    }
  )

  const createSuggestionsMutation: UseMutationResult<ThreadWithSuggestions, Error, createSuggestionsMutation> = useMutation(
    {
      mutationFn: async (args: createSuggestionsMutation): Promise<ThreadWithSuggestions> => (
        await client.createThreadWithSuggestions(
          args.userId,
          {
            hobbies: formValue.hobbies,
            movie_genre: formValue.movie_genre,
            day_off_preferences: formValue.day_off_preferences,
            desired_problems: formValue.desired_problems,
            tool_choice: formValue.tool_choice,
            favorite_class: formValue.favorite_class,
            previously_built: formValue.previously_built,
            work_independence_preferences: formValue.work_independence_preferences,
            dream_workspace: formValue.dream_workspace,
            favorite_work: formValue.favorite_work,
            perks_preferences: formValue.perks_preferences,
            feedback: formValue.feedback,
            preferred_learning_style: formValue.preferred_learning_style,
            role_on_project: formValue.role_on_project,
            interesting_topics: formValue.interesting_topics,
            theme_song: formValue.theme_song,
            time_machine: formValue.time_machine,
            management_experience: formValue.management_experience,
            ideal_career_alignment: formValue.ideal_career_alignment,
            enjoyable_tasks: formValue.enjoyable_tasks,
            superpower_scales: formValue.superpower_scales,
            group_role_preference_scales: formValue.group_role_preference_scales,
            security_preference_scales: formValue.security_preference_scales,
            creative_preferences_scales: formValue.creative_preferences_scales,
            work_pace_scales: formValue.work_pace_scales,
            innovative_creative_preferences_scales: formValue.innovative_creative_preferences_scales,
            confidence_learning_new_skills: formValue.confidence_learning_new_skills,
            notes: formValue.notes,
          }
        )
      ),
      onSuccess: (result: ThreadWithSuggestions): void => {
        queryClient.invalidateQueries({
          queryKey: ['threads', authorizationContext.value?.user_id ?? '']
        })

        navigate(constructThreadsSuggestionsRoute(result.thread_id), {
          state: result
        })

        notify('Submission processed successfully!', 'success')
      },
      onError: (error: Error): void => {
        notify('There was an error processing your submission.', 'error')

        if (error instanceof BadRequestError) {
          createSuggestionsMutation.reset()
          return
        }

        throw error
      }
    })

  if (limitsLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <div className="w-[30px] md:w-[40px] rounded-full border-[4px] border-[#e4e0e1] border-r-[#0035ff] spinner"/>

        <div className="mt-4 md:mt-6"/>

        <p className="text-[12px] md:text-[16px]">
          {'Loading...'}
        </p>
      </div>
    )
  }

  if (createSuggestionsMutation.isPending) {
    return (
      <div className="flex flex-col justify-center items-center h-full text-center">
        <div className="flex flex-col h-fit justify-center items-center">
          <div className="spinner w-[30px] md:w-[40px] rounded-full border-[4px] border-[#e4e0e1] border-r-[#0035ff]"/>

          <div className="mt-4 md:mt-6"/>

          <p className="text-[14px] md:text-[16px]">
            {'Thank you!'}
          </p>
          <p className="text-[14px] md:text-[16px]">
            {'You have made the first step towards finding your passion.'}
          </p>
          <p className="text-[14px] md:text-[16px]">
            {'This may take a moment. We’re analyzing your input to create personalized suggestions.'}
          </p>
        </div>

        <div className="mt-10 md:mt-14" />

        <QuotesBlock />
      </div>
    )
  }

  if (limits?.suggestions_hit || (createSuggestionsMutation.isError && createSuggestionsMutation.error instanceof TooManyRequestsError)) {
    return (
      <div className="flex flex-col justify-center items-center text-center h-full text-[14px] md:text-[16px]">
        <p>{'Oops!'}</p>

        <p>{'You’ve hit the daily limit of three suggestions per day.'}</p>
        <p>{'Please try again tomorrow. Thank you for your understanding!'}</p>
      </div>
    )
  }

  if (createSuggestionsMutation.isError) {
    const tryAgainButtonClick = async (): Promise<void> => {
      if (currentFormStepIndex === lastFormStepIndex) {
        await createSuggestionsMutation.mutate({
          userId: authorizationContext.value?.user_id ?? '',
          create: {
            hobbies: formValue.hobbies,
            movie_genre: formValue.movie_genre,
            day_off_preferences: formValue.day_off_preferences,
            desired_problems: formValue.desired_problems,
            tool_choice: formValue.tool_choice,
            favorite_class: formValue.favorite_class,
            previously_built: formValue.previously_built,
            work_independence_preferences: formValue.work_independence_preferences,
            dream_workspace: formValue.dream_workspace,
            favorite_work: formValue.favorite_work,
            perks_preferences: formValue.perks_preferences,
            feedback: formValue.feedback,
            preferred_learning_style: formValue.preferred_learning_style,
            role_on_project: formValue.role_on_project,
            interesting_topics: formValue.interesting_topics,
            theme_song: formValue.theme_song,
            time_machine: formValue.time_machine,
            management_experience: formValue.management_experience,
            ideal_career_alignment: formValue.ideal_career_alignment,
            enjoyable_tasks: formValue.enjoyable_tasks,
            superpower_scales: formValue.superpower_scales,
            group_role_preference_scales: formValue.group_role_preference_scales,
            security_preference_scales: formValue.security_preference_scales,
            creative_preferences_scales: formValue.creative_preferences_scales,
            work_pace_scales: formValue.work_pace_scales,
            innovative_creative_preferences_scales: formValue.innovative_creative_preferences_scales,
            confidence_learning_new_skills: formValue.confidence_learning_new_skills,
            notes: formValue.notes,
          }
        })

        return
      }
    }

    return (
      <div className="flex flex-col items-center justify-center h-full text-center">
        <p className="text-[14px] md:text-[16px]">{'We are sorry, there was an unknown error with your submission.'}</p>
        <p className="text-[14px] md:text-[16px]">{'Our team has been already informed and we are working on resolving the issue.'}</p>

        <div className="mt-2 md:mt-4"/>

        <a
          className="text-[14px] md:text-[16px] text-[#0035ff] cursor-pointer"
          href={`mailto:${helloEmail}`}
        >
          {'Either reach out to us'}
        </a>

        <div className="mt-2 md:mt-4"/>

        <p className="text-[14px] md:text-[16px]">{'or'}</p>

        <div className="mt-2 md:mt-4"/>

        <button
          className="flex justify-between items-center p-3 rounded-[10px] bg-[#0035ff] text-white transition hover:bg-[#0029c4]"
          onClick={() => tryAgainButtonClick()}
        >
          <TryAgainIcon className="w-[16px] h-[16px] md:w-[18px] md:h-[18px] fill-transparent stroke-white"/>

          <div className="ml-2"/>

          <p className="text-[14px] md:text-[16px]">
            {'Try again'}
          </p>
        </button>
      </div>
    )
  }

  const previousFormButton = (): boolean | ReactElement => (
    <div
      className={
        classNames(
          'w-full',
          {
            'hidden': currentFormStepIndex === 1
          }
        )
      }
    >
      <button
        className="flex items-center text-[12px] md:text-[14px] hover:text-[#575757] group hover:color-[#575757]"
        onClick={() => setCurrentFormStepIndex((prev: number) => prev - 1)}
      >
        <ArrowLeftIcon className="w-[13px] h-[13px] md:w-[15px] md:h-[15px] duration-300 ease-in-out group-hover:fill-[#575757]"/>

        <div className="ml-1"/>

        <p>
          {'Previous step'}
        </p>
      </button>

      <div className="mb-6 md:mb-8"/>
    </div>
  )

  const setArrayValue = <K extends keyof CreateSuggestions>(key: K, value: string): void => {
    setFormValue((prevState: CreateSuggestions) => {
      const newValues: string[] = [...prevState[key] as []]

      const index: number = newValues.indexOf(value)
      if (index > -1) {
        newValues.splice(index, 1)
      } else {
        newValues.push(value)
      }

      return {
        ...prevState,
        [key]: newValues
      }
    })
  }

  const setScaleValue = <K extends keyof CreateSuggestions>(key: K, value: number, index: number): void => {
    setFormValue((prevState: CreateSuggestions) => {
      let newValues: number[] = [...prevState[key] as []]
      newValues[index] = value

      return {
        ...prevState,
        [key]: newValues
      }
    })
  }

  const setStringValue = <K extends keyof CreateSuggestions>(key: K, value: string): void => {
    setFormValue((prevState: CreateSuggestions) => {
      return {
        ...prevState,
        [key]: value
      }
    })
  }

  const validateFormStep = (): boolean => {
    switch (currentFormStepIndex) {
    case 1: {
      if (formValue.superpower_scales.includes(0)) {
        return false
      }

      if (formValue.movie_genre === '') {
        return false
      }

      if (formValue.day_off_preferences === '') {
        return false
      }

      if (formValue.tool_choice === '') {
        return false
      }

      if (formValue.favorite_class === '') {
        return false
      }

      if (formValue.hobbies.length === 0) {
        return false
      }

      return true
    }
    case 2: {
      if (formValue.group_role_preference_scales.includes(0)) {
        return false
      }

      if (formValue.work_independence_preferences === '') {
        return false
      }

      if (formValue.dream_workspace === '') {
        return false
      }

      if (formValue.perks_preferences.length === 0) {
        return false
      }

      if (formValue.feedback === '') {
        return false
      }

      if (formValue.security_preference_scales.includes(0)) {
        return false
      }

      if (formValue.preferred_learning_style === '') {
        return false
      }

      if (formValue.creative_preferences_scales.includes(0)) {
        return false
      }

      if (formValue.role_on_project === '') {
        return false
      }

      if (formValue.work_pace_scales.includes(0)) {
        return false
      }

      if (formValue.previously_built === '') {
        return false
      }

      return true
    }
    case 3: {
      if (formValue.ideal_career_alignment === '') {
        return false
      }

      if (formValue.theme_song === '') {
        return false
      }

      if (formValue.interesting_topics.length === 0) {
        return false
      }

      if (formValue.time_machine === '') {
        return false
      }

      if (formValue.management_experience === '') {
        return false
      }

      if (formValue.innovative_creative_preferences_scales.includes(0)) {
        return false
      }

      if (formValue.confidence_learning_new_skills === '') {
        return false
      }

      return true
    }
    }

    return true
  }

  const randomItemFromValues = (values: string[]): string | undefined =>
    values.length !== 0 ? values[Math.floor(Math.random() * values.length)] : undefined

  const formSteps = (): ReactElement[] => {
    const submit = async (event: FormEvent): Promise<void> => {
      event.preventDefault()

      if (!validateFormStep()) {
        notify('Please complete all required fields.', 'error')
        return
      }

      if (currentFormStepIndex === lastFormStepIndex) {
        await createSuggestionsMutation.mutate({
          userId: authorizationContext.value?.user_id ?? '',
          create: {
            hobbies: formValue.hobbies,
            movie_genre: formValue.movie_genre,
            day_off_preferences: formValue.day_off_preferences,
            desired_problems: formValue.desired_problems,
            tool_choice: formValue.tool_choice,
            favorite_class: formValue.favorite_class,
            previously_built: formValue.previously_built,
            work_independence_preferences: formValue.work_independence_preferences,
            dream_workspace: formValue.dream_workspace,
            favorite_work: formValue.favorite_work,
            perks_preferences: formValue.perks_preferences,
            feedback: formValue.feedback,
            preferred_learning_style: formValue.preferred_learning_style,
            role_on_project: formValue.role_on_project,
            interesting_topics: formValue.interesting_topics,
            theme_song: formValue.theme_song,
            time_machine: formValue.time_machine,
            management_experience: formValue.management_experience,
            ideal_career_alignment: formValue.ideal_career_alignment,
            enjoyable_tasks: formValue.enjoyable_tasks,
            superpower_scales: formValue.superpower_scales,
            group_role_preference_scales: formValue.group_role_preference_scales,
            security_preference_scales: formValue.security_preference_scales,
            creative_preferences_scales: formValue.creative_preferences_scales,
            work_pace_scales: formValue.work_pace_scales,
            innovative_creative_preferences_scales: formValue.innovative_creative_preferences_scales,
            confidence_learning_new_skills: formValue.confidence_learning_new_skills,
            notes: formValue.notes,
          }
        })

        return
      }

      setCurrentFormStepIndex((prev: number) => prev + 1)
    }

    return [
      <FormStep
        key={0}
        title={'1: Personal insights'}
        submit={submit}
      >
        <div>
          <p className="text-[12px] lg:text-[14px]">
            {'Rate how much do you relate to the superpowers'}
          </p>

          <div className="mt-4"/>

          <ScaleInput
            statement={'I see patterns in chaos.'}
            value={formValue.superpower_scales[0]}
            changeValue={(value: number): void => setScaleValue('superpower_scales', value, 0)}
            disagreeLabel={'Not me'}
            agreeLabel={'Totally me'}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I can talk people into anything.'}
            value={formValue.superpower_scales[1]}
            changeValue={(value: number): void => setScaleValue('superpower_scales', value, 1)}
            disagreeLabel={'Not me'}
            agreeLabel={'Totally me'}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I enjoy creating things from scratch.'}
            value={formValue.superpower_scales[2]}
            changeValue={(value: number): void => setScaleValue('superpower_scales', value, 2)}
            disagreeLabel={'Not me'}
            agreeLabel={'Totally me'}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I enjoy fixing things that are broken.'}
            value={formValue.superpower_scales[3]}
            changeValue={(value: number): void => setScaleValue('superpower_scales', value, 3)}
            disagreeLabel={'Not me'}
            agreeLabel={'Totally me'}
          />
        </div>

        <div className="mt-8"/>

        <RadioInput
          label={'If your life was a movie, what genre would it be?'}
          items={[
            'Sci-fi',
            'Thriller',
            'Comedy',
            'Adventure',
            'Mystery',
          ]}
          value={formValue.movie_genre}
          setValue={(value: string): void => setStringValue('movie_genre', value)}
        />

        <div className="mt-8"/>

        <RadioInput
          label={'Imagine you have a day off. What’s your first move?'}
          items={[
            'Build or tinker with something cool',
            'Plan a fun group activity with friends',
            'Sketch, write, or create',
            'Binge-watch documentaries or read a deep book',
          ]}
          value={formValue.day_off_preferences}
          setValue={(value: string): void => setStringValue('day_off_preferences', value)}
        />

        <div className="mt-8"/>

        <div>
          <p className="text-[12px] lg:text-[14px]">
            {'What kind of problems would you like to solve?'}
          </p>

          <div className="mt-4"/>

          <SliderInput
            statement={'I like saving the planet.'}
            value={formValue.desired_problems[0]}
            changeValue={(value: number): void => setScaleValue('desired_problems', value, 0)}
          />

          <div className="mt-4"/>

          <SliderInput
            statement={'I enjoy building better gadgets.'}
            value={formValue.desired_problems[1]}
            changeValue={(value: number): void => setScaleValue('desired_problems', value, 1)}
          />

          <div className="mt-4"/>

          <SliderInput
            statement={'I am motivated to help people feel their best.'}
            value={formValue.desired_problems[2]}
            changeValue={(value: number): void => setScaleValue('desired_problems', value, 2)}
          />

          <div className="mt-4"/>

          <SliderInput
            statement={'I bring a creative vision to life.'}
            value={formValue.desired_problems[3]}
            changeValue={(value: number): void => setScaleValue('desired_problems', value, 3)}
          />
        </div>

        <div className="mt-8"/>

        <RadioInput
          label={'If your career was a journey, what would you pack?'}
          items={[
            'A toolkit to fix any problem',
            'A map to plan the best route',
            'A journal to document your adventures',
            'Snacks for making friends along the way',
          ]}
          value={formValue.tool_choice}
          setValue={(value: string): void => setStringValue('tool_choice', value)}
        />

        <div className="mt-8"/>

        <RadioInput
          label={'What kind of class did you always like the most in school?'}
          items={[
            'Science experiments and tech projects',
            'Art and music',
            'Leadership or debate clubs',
            'Anything hands-on, like shop or cooking'
          ]}
          value={formValue.favorite_class}
          setValue={(value: string): void => setStringValue('favorite_class', value)}
        />

        <div className="mt-8"/>

        <MultiRadioInput
          label={'What kind of activities or hobbies do you enjoy in your free time?'}
          items={[
            'Playing or creating games',
            'Working on DIY or craft projects',
            'Learning new skills',
            'Traveling and exploring new places',
            'Helping others',
            'Artistic activities',
            'Fitness and sports',
            'Reading or writing',
          ]}
          checked={formValue.hobbies}
          onCheck={(value: string): void => setArrayValue('hobbies', value)}
          max={3}
        />
      </FormStep>
      ,
      <FormStep
        key={1}
        title={'2: Work preferences & skills'}
        submit={submit}
      >
        <div>
          <p className="text-[12px] lg:text-[14px]">
            {'How comfortable are you in these roles?'}
          </p>

          <div className="mt-4"/>

          <ScaleInput
            statement={'In a group, I am always the planning and preparing person.'}
            value={formValue.group_role_preference_scales[0]}
            changeValue={(value: number): void => setScaleValue('group_role_preference_scales', value, 0)}
            disagreeLabel={'Not at all'}
            agreeLabel={'Absolutely'}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I focus on making others feel comfortable.'}
            value={formValue.group_role_preference_scales[1]}
            changeValue={(value: number): void => setScaleValue('group_role_preference_scales', value, 1)}
            disagreeLabel={'Not at all'}
            agreeLabel={'Absolutely'}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I enjoy being in the center of attention.'}
            value={formValue.group_role_preference_scales[2]}
            changeValue={(value: number): void => setScaleValue('group_role_preference_scales', value, 2)}
            disagreeLabel={'Not at all'}
            agreeLabel={'Absolutely'}
          />
        </div>

        <div className="mt-8"/>

        <RadioInput
          label={'How comfortable are you with working independently?'}
          items={[
            'I enjoy working alone on tasks',
            'I prefer collaborating but can work independently',
            'I struggle without a team to bounce ideas off',
          ]}
          value={formValue.work_independence_preferences}
          setValue={(value: string): void => setStringValue('work_independence_preferences', value)}
        />

        <div className="mt-8"/>

        <RadioInput
          label={'Pick a dream workspace:'}
          items={[
            'A high-tech lab with gadgets galore',
            'A cozy creative studio with paints and tools',
            'A buzzing office full of energy',
            'A peaceful library for deep focus',
          ]}
          value={formValue.dream_workspace}
          setValue={(value: string): void => setStringValue('dream_workspace', value)}
        />

        <div className="mt-4"/>

        <div>
          <p className="text-[12px] lg:text-[14px]">
            {'How much do you like this level of work?'}
          </p>

          <div className="mt-4"/>

          <SliderInput
            statement={'I enjoy leading a team to victory.'}
            value={formValue.favorite_work[0]}
            changeValue={(value: number): void => setScaleValue('favorite_work', value, 0)}
          />

          <div className="mt-4"/>

          <SliderInput
            statement={'I design and strategize.'}
            value={formValue.favorite_work[1]}
            changeValue={(value: number): void => setScaleValue('favorite_work', value, 1)}
          />

          <div className="mt-4"/>

          <SliderInput
            statement={'I love to solve a tough puzzle.'}
            value={formValue.favorite_work[2]}
            changeValue={(value: number): void => setScaleValue('favorite_work', value, 2)}
          />

          <div className="mt-4"/>

          <SliderInput
            statement={'Celebrating an accomplishment with others is fun.'}
            value={formValue.favorite_work[3]}
            changeValue={(value: number): void => setScaleValue('favorite_work', value, 3)}
          />
        </div>

        <div className="mt-8"/>

        <MultiRadioInput
          label={'Which of these perks would you like the most at work?'}
          items={[
            'Access to the latest tech toys',
            'Freedom to work on your own creative projects',
            'Being part of an awesome team',
            'Endless coffee and snacks',
          ]}
          checked={formValue.perks_preferences}
          onCheck={(value: string): void => setArrayValue('perks_preferences', value)}
          max={2}
        />

        <div className="mt-8"/>

        <div>
          <p className="text-[12px] lg:text-[14px]">
            {'How much do you enjoy doing these tasks?'}
          </p>

          <div className="mt-4"/>

          <SliderInput
            statement={'Analyzing data and making informed decisions.'}
            value={formValue.enjoyable_tasks[0]}
            changeValue={(value: number): void => setScaleValue('enjoyable_tasks', value, 0)}
          />

          <div className="mt-4"/>

          <SliderInput
            statement={'Designing or creating something new.'}
            value={formValue.enjoyable_tasks[1]}
            changeValue={(value: number): void => setScaleValue('enjoyable_tasks', value, 1)}
          />

          <div className="mt-4"/>

          <SliderInput
            statement={'Leading and motivating a team.'}
            value={formValue.enjoyable_tasks[2]}
            changeValue={(value: number): void => setScaleValue('enjoyable_tasks', value, 2)}
          />

          <div className="mt-4"/>

          <SliderInput
            statement={'Providing support and helping others.'}
            value={formValue.enjoyable_tasks[3]}
            changeValue={(value: number): void => setScaleValue('enjoyable_tasks', value, 3)}
          />
        </div>

        <div className="mt-8"/>

        <RadioInput
          label={'How do you prefer to receive feedback at work?'}
          items={[
            'Direct and straightforward',
            'Positive, with some constructive suggestions',
            'Detailed, with examples',
            'I prefer to figure it out on my own',
          ]}
          value={formValue.feedback}
          setValue={(value: string): void => setStringValue('feedback', value)}
        />

        <div className="mt-8"/>

        <div>
          <p className="text-[12px] lg:text-[14px]">
            {'How much do you value job security and stability?'}
          </p>

          <div className="mt-4"/>

          <ScaleInput
            statement={'I prefer a steady and predictable work environment.'}
            value={formValue.security_preference_scales[0]}
            changeValue={(value: number): void => setScaleValue('security_preference_scales', value, 0)}
            disagreeLabel={'Not important'}
            agreeLabel={'Very important'}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I’m open to risks, but I still value a stable income.'}
            value={formValue.security_preference_scales[1]}
            changeValue={(value: number): void => setScaleValue('security_preference_scales', value, 1)}
            disagreeLabel={'Not important'}
            agreeLabel={'Very important'}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I thrive in dynamic, ever-changing roles.'}
            value={formValue.security_preference_scales[2]}
            changeValue={(value: number): void => setScaleValue('security_preference_scales', value, 2)}
            disagreeLabel={'Not important'}
            agreeLabel={'Very important'}
          />
        </div>

        <div className="mt-8"/>

        <RadioInput
          label={'Which of these describes your preferred learning style?'}
          items={[
            'Hands-on experience and learning by doing',
            'Reading and researching in-depth',
            'Visual demonstrations and videos',
            'Discussions and group activities',
          ]}
          value={formValue.preferred_learning_style}
          setValue={(value: string): void => setStringValue('preferred_learning_style', value)}
        />

        <div className="mt-4"/>

        <div>
          <p className="text-[12px] lg:text-[14px]">
            {'How important is creative expression in your work?'}
          </p>

          <div className="mt-4"/>

          <ScaleInput
            statement={'I need to express myself creatively to feel fulfilled.'}
            value={formValue.creative_preferences_scales[0]}
            changeValue={(value: number): void => setScaleValue('creative_preferences_scales', value, 0)}
            disagreeLabel={'Not important'}
            agreeLabel={'Very important'}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I appreciate creativity but don’t need it in every task.'}
            value={formValue.creative_preferences_scales[1]}
            changeValue={(value: number): void => setScaleValue('creative_preferences_scales', value, 1)}
            disagreeLabel={'Not important'}
            agreeLabel={'Very important'}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I prefer to work in structured, logical environments with little room for creativity.'}
            value={formValue.creative_preferences_scales[2]}
            changeValue={(value: number): void => setScaleValue('creative_preferences_scales', value, 2)}
            disagreeLabel={'Not important'}
            agreeLabel={'Very important'}
          />
        </div>

        <div className="mt-8"/>

        <RadioInput
          label={'If you were given a project at work, what would your role most likely be?'}
          items={[
            'I’d be the project manager, overseeing everything',
            'I’d be the creative mind behind the project',
            'I’d focus on the technical aspects and implementation',
            'I’d support the team and make sure everything runs smoothly',
          ]}
          value={formValue.role_on_project}
          setValue={(value: string): void => setStringValue('role_on_project', value)}
        />

        <div className="mt-8"/>

        <div>
          <p className="text-[12px] lg:text-[14px]">
            {'What’s your ideal work pace?'}
          </p>

          <div className="mt-4"/>

          <ScaleInput
            statement={'I enjoy fast-paced and ever-changing work.'}
            value={formValue.work_pace_scales[0]}
            changeValue={(value: number): void => setScaleValue('work_pace_scales', value, 0)}
          />
        </div>

        <div className="mt-8"/>

        <RadioInput
          label={'Have you ever built or created something you\'re proud of?'}
          items={[
            'Yes, a software or tech project',
            'Yes, an artistic or creative project (design, writing, etc.)',
            'Yes, a business or startup idea',
            'Yes, a product or physical item (e.g., crafting, building, cooking)',
            'No, but I would love to',
            'Not yet, but I am interested in creating something'
          ]}
          value={formValue.previously_built}
          setValue={(value: string): void => setStringValue('previously_built', value)}
        />
      </FormStep>,
      <FormStep
        key={2}
        title={'3: Career vision & interests'}
        submit={submit}
      >
        <RadioInput
          label={'Which of these values aligns most with your ideal career?'}
          items={[
            'Creativity and innovation',
            'Helping others and making a difference',
            'Stability and security',
            'Independence and autonomy',
          ]}
          value={formValue.ideal_career_alignment}
          setValue={(value: string): void => setStringValue('ideal_career_alignment', value)}
        />

        <div className="mt-8"/>

        <RadioInput
          label={'If your career could have a theme song, what would it be?'}
          items={[
            'A high-energy techno beat',
            'An inspiring symphony',
            'A rock anthem',
            'A chill, feel-good vibe',
          ]}
          value={formValue.theme_song}
          setValue={(value: string): void => setStringValue('theme_song', value)}
        />

        <div className="mt-8"/>

        <CategoriesSelectInput
          checked={formValue.interesting_topics}
          onCheck={(value: string): void => setArrayValue('interesting_topics', value)}
          max={5}
        />

        <div className="mt-8"/>

        <RadioInput
          label={'If you had a time machine, where would you go?'}
          items={[
            'The future to see the latest tech',
            'The Renaissance for the art',
            'The Industrial Revolution to build cool stuff',
            'Any time where I can meet awesome people',
          ]}
          value={formValue.time_machine}
          setValue={(value: string): void => setStringValue('time_machine', value)}
        />

        <div className="mt-8"/>

        <RadioInput
          label={'Have you ever led or managed a team?'}
          items={[
            'Yes, I have experience leading teams',
            'I’ve led smaller projects or groups',
            'I prefer not to lead, but I collaborate well',
            'No, I have not had the opportunity yet',
          ]}
          value={formValue.management_experience}
          setValue={(value: string): void => setStringValue('management_experience', value)}
        />

        <div className="mt-8"/>

        <div>
          <p className="text-[12px] lg:text-[14px]">
            {'How strongly do you feel about innovating and creating new things?'}
          </p>

          <div className="mt-4"/>

          <ScaleInput
            statement={'I want to help and make life better for others.'}
            value={formValue.innovative_creative_preferences_scales[0]}
            changeValue={(value: number): void => setScaleValue('innovative_creative_preferences_scales', value, 0)}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I want to solve important global issues.'}
            value={formValue.innovative_creative_preferences_scales[1]}
            changeValue={(value: number): void => setScaleValue('innovative_creative_preferences_scales', value, 1)}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I want to lead and inspire others.'}
            value={formValue.innovative_creative_preferences_scales[2]}
            changeValue={(value: number): void => setScaleValue('innovative_creative_preferences_scales', value, 2)}
          />

          <div className="mt-8"/>

          <ScaleInput
            statement={'I want to build something lasting, like a business or community.'}
            value={formValue.innovative_creative_preferences_scales[3]}
            changeValue={(value: number): void => setScaleValue('innovative_creative_preferences_scales', value, 3)}
          />
        </div>

        <div className="mt-8"/>

        <RadioInput
          label={'Do you feel confident in learning new skills?'}
          items={[
            'Yes, I love learning new skills and am always seeking to improve',
            'I am open to learning new skills, but I prefer a structured approach',
            'I prefer to stick to the skills I already know',
          ]}
          value={formValue.confidence_learning_new_skills}
          setValue={(value: string): void => setStringValue('confidence_learning_new_skills', value)}
        />
      </FormStep>,
      <FormStep
        key={3}
        title={'Anything else to add?'}
        submit={submit}
      >
        <TextArea
          label={'Notes'}
          value={formValue.notes}
          placeholder={randomItemFromValues(notesPlaceholders)}
          setValue={(value: string): void => setStringValue('notes', value)}
        />
      </FormStep>
    ]
  }

  return (
    <div className="m-auto lg:w-[800px] lg:max-w-[800px]">
      {previousFormButton()}

      <FormStepsBar
        step={currentFormStepIndex}
        max={formSteps().length}
      />

      <div className="mt-8 lg:mt-10"/>

      {formSteps().map((formStep: ReactElement, index: number) => (
        currentFormStepIndex === index + 1 && formStep
      ))}
    </div>
  )
}

export default CreateSuggestionsForm
