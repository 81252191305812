import React, { ReactElement } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

type radioInputProps = {
    checked: string[]
    onCheck: (_v: string) => void
    max?: number
}

const CategoriesSelectInput: React.FC<radioInputProps> = ({
  checked,
  onCheck,
  max = 0,
}): ReactElement => {
  const categories = [
    '🐶️ Animals',
    '🔭 Astronomy',
    '🏛️ Architecture',
    '🚗 Automotive & Transportation',
    '💼 Business & Entrepreneurship',
    '💬 Communication',
    '🎨 Creative Arts',
    '️⚙️ Design',
    '🎓 Education',
    '👗 Fashion & Beauty',
    '🎥 Film',
    '📈 Finance & Investing',
    '🏋🏻‍♂️ Fitness',
    '🍔 Food',
    '🎮 Entertainment & Gaming',
    '🧘 Health & Wellness',
    '📖 History & Culture',
    '💡 Innovation',
    '⚖️ Law & Politics',
    '📚 Literature',
    '🎯 Marketing',
    '🌟 Mentoring & Growth',
    '🎤 Music & Performing arts',
    '💭 Philosophy',
    '📸 Photography',
    '🧠 Psychology',
    '🔬 Science & Research',
    '⚽ Sports',
    '🌍 Social impact',
    '📱 Social media',
    '🌱 Sustainability',
    '🏝️️ Travel',
    '💻 Technology',
    '👦 Youth education',
    '✏️ Writing',
  ]

  const handleClick = (category: string): void => {
    if (checked.includes(category)) {
      onCheck(category)
      return
    }

    if (max === 0) {
      onCheck(category)
      return
    }

    if (checked.length < max) {
      onCheck(category)
    }
  }

  return (
    <div className="flex-col">
      <p className="text-[12px] lg:text-[14px]">
        {'What topics are you most interested in?'}
      </p>

      <div className="mt-2"/>

      <div className="flex flex-wrap justify-center overflow-hidden px-2">
        {categories.map((category: string, index: number) => (
          <div
            key={index}
            className={
              classNames(
                'py-1 px-2 m-1 rounded-[10px] cursor-pointer bg-[#e2e3f8] border-[1px] hover:border-[#0035ff] transition',
                { 'border-[#0035ff]': checked.includes(category) }
              )
            }
            onClick={() => handleClick(category)}
          >
            <p className="text-[10px] xl:text-[13px]">{category}</p>
          </div>
        ))}
      </div>

      <div className="mt-4"/>

      {max !== 0 && (
        <p className="text-[10px] lg:text-[12px] text-[#575757] text-right">
          {`${checked.length}/${max}`}
        </p>
      )}
    </div>
  )
}

CategoriesSelectInput.propTypes = {
  checked: PropTypes.array.isRequired,
  max: PropTypes.number,
}

export default CategoriesSelectInput
