import React, { ReactElement, useMemo, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import Navigation from '../components/Navigation'
import { AuthorizationContext, useAuthorization } from '../context/authorization.context'
import HttpClient from '../services/http/client'
import Client from '../services/http/client'
import { useQuery } from '@tanstack/react-query'
import { Suggestion as TSuggestion, Suggestion } from '../types/types'
import { helloEmail } from '../constants/constants'
import { NotFoundError } from '../services/http/errors'
import classNames from 'classnames'
import { suggestionDemandFieldStyles, suggestionMatchRatingColor } from '../components/helpers'
import { ReactComponent as SearchIcon } from '../media/search-icon.svg'
import Steps from '../components/Steps'

const ThreadShare = (): ReactElement => {
  const authorizationContext: AuthorizationContext = useAuthorization()
  const { threadShareId } = useParams()

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(0)

  const client: HttpClient = useMemo((): Client => new HttpClient(
    process.env.REACT_APP_HTTP_SERVER_ADDRESS ?? 'http://127.0.0.1:8080',
    authorizationContext,
  ), [authorizationContext])

  const {
    data: threadShareSuggestions,
    isLoading: threadShareSuggestionsLoading,
    isError: threadShareSuggestionsIsError,
    error: threadShareSuggestionsError
  } = useQuery(
    {
      queryKey: ['thread_share_suggestions', authorizationContext.value?.user_id],
      queryFn: async (): Promise<Suggestion[]> =>
        client.threadShareSuggestions(threadShareId ?? ''),
    }
  )

  const contentWrapper = (children: ReactElement): ReactElement => (
    <>
      <div className="flex flex-col w-full fixed z-50">
        <Navigation showFullLogo={true} />
      </div>

      {children}
    </>
  )

  if (threadShareSuggestionsLoading) {
    return contentWrapper(
      <div className="flex flex-col justify-center items-center w-auto max-w-[1000px] h-full">
        <div className="w-[30px] md:w-[40px] rounded-full border-[4px] border-[#e4e0e1] border-r-[#0035ff] spinner"/>

        <div className="mt-4 md:mt-4"/>

        <p className="text-[12px] md:text-[16px]">
          {'Loading...'}
        </p>
      </div>
    )
  }

  if (threadShareSuggestionsError instanceof NotFoundError) {
    return <Navigate to={'/not-found'} />
  }

  if (threadShareSuggestionsIsError) {
    return contentWrapper(
      <div className="flex flex-col justify-center items-center w-auto max-w-[1000px] h-full">
        <p className="text-[14px] md:text-[16px]">{'We are sorry, there was an unknown error with displaying shared link.'}</p>
        <p className="text-[14px] md:text-[16px]">{'Our team has been already informed and we are working on resolving the issue.'}</p>

        <div className="mt-2 md:mt-4"/>

        <div className="flex">
          <a
            className="text-[14px] md:text-[16px] text-[#0035ff] cursor-pointer"
            href={`mailto:${helloEmail}`}>
            {'You can reach out to us here'}
          </a>
          <p className="text-[14px] md:text-[16px]">{'.'}</p>
        </div>
      </div>
    )
  }

  if (!threadShareSuggestions || threadShareSuggestions.length === 0) {
    return contentWrapper(
      <div className="flex flex-col justify-center items-center w-auto max-w-[1000px] h-full">
        <SearchIcon className="w-[20px] h-[20px] md:w-[25px] md:h-[25px] fill-transparent stroke-[#0035ff]"/>

        <div className="mt-4"/>

        <p className="text-[14px] lg:text-[16px]">{'Oops, nothing found.'}</p>

        <div className="mt-2"/>

        <p className="text-[14px] lg:text-[16px] flex whitespace-pre">
          {'Please, try again, or feel free to '}
          <a className="text-[14px] lg:text-[16px] text-[#0035ff] cursor-pointer">{'contact us'}</a>
          {'.'}
        </p>
      </div>
    )
  }

  const matchRating = (value: number): ReactElement => {
    const color: string = suggestionMatchRatingColor(value)

    return (
      <div
        className="relative rounded-full w-[36px] h-[36px] xl:w-[40px] xl:h-[40px] flex justify-center items-center"
        style={{
          background: `conic-gradient(${color} 0% ${value}%, #e4e0e1 ${value}% 100%)`
        }}
      >
        <div className="absolute rounded-full bg-white w-[28px] h-[28px] xl:w-[32px] xl:h-[32px]" />
        <p className="text-[12px] xl:text-[14px] text-[#575757] z-[1]">
          {value}
        </p>
      </div>
    )
  }

  return contentWrapper(
    <div className="flex flex-col justify-start w-auto max-w-[1000px] h-full items-center">
      <div className="flex flex-col h-[100svh] overflow-y-scroll md:h-auto p-8 md:m-4 md:py-10 lg:px-0 md:w-full">
        <div className="mt-6"/>

        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-stretch w-full">
          {threadShareSuggestions?.map(({ title }: TSuggestion, index: number): ReactElement => (
            <div
              key={index}
              className={
                classNames(
                  'flex sm:items-center w-full md:w-auto sm:justify-center sm:max-w-[12rem] first:ml-0 sm:ml-4 xl:ml-6 p-2 text-center cursor-pointer transition border-b-[2px] border-[#e4e0e1]',
                  {
                    'border-b-[#0035ff] text-[#0035ff]': index === activeSuggestionIndex,
                  }
                )
              }
              onClick={(): void => setActiveSuggestionIndex(index)}
            >
              <p className="text-[12px] xl:text-[14px] text-center">
                {title}
              </p>
            </div>
          ))}
        </div>

        <div className="mt-4 xl:mt-6"/>

        <div className="flex flex-col sm:overflow-hidden h-full">
          <div className="flex flex-col h-full">
            <div className="flex items-center">
              <p className="text-[28px] xl:text-[34px] font-[500] text-[#0035ff]">
                {threadShareSuggestions[activeSuggestionIndex].title}
              </p>
            </div>

            <div className="mt-4 xl:mt-6"/>

            <div className="flex before:content-[''] before:h-[1px] before:bg-[#e4e0e1] before:flex-1"/>

            <div className="flex flex-col sm:flex-row justify-between sm:overflow-hidden h-full relative">
              <div className="sm:w-1/2 h-full pt-2 xl:pt-4 pr-6 xl:pr-10 sm:overflow-y-scroll">
                <div className="mt-4 xl:mt-6"/>

                {threadShareSuggestions[activeSuggestionIndex].match_rating && (
                  <>
                    <p className="text-[12px] xl:text-[14px] font-[500]">
                      {'Match rating'}
                    </p>

                    <div className="mt-2"/>

                    {matchRating(threadShareSuggestions[activeSuggestionIndex].match_rating ?? 0)}

                    <div className="mt-4"/>
                  </>
                )}

                <p className="text-[12px] xl:text-[14px] font-[500]">
                  {'Description'}
                </p>

                <div className="mt-2"/>

                <p className="text-[12px] xl:text-[14px] text-[#575757] text-wrap">
                  {threadShareSuggestions[activeSuggestionIndex].description}
                </p>

                <div className="mt-4"/>

                <p className="text-[12px] xl:text-[14px] font-[500]">
                  {'Skills'}
                </p>

                <div className="mt-2"/>

                <ul className="text-[12px] xl:text-[14px] text-[#575757] list-disc pl-6">
                  {threadShareSuggestions[activeSuggestionIndex].skills.split(', ').map((skill: string, index: number) => (
                    <li
                      key={index}
                      className="text-[12px] xl:text-[14px] text-[#575757]"
                    >
                      {skill}
                    </li>
                  ))}
                </ul>

                <div className="mt-2"/>

                <p className="text-[12px] xl:text-[14px] font-[500]">
                  {'Demand'}
                </p>

                <div className="mt-2"/>

                <p className={`text-[12px] xl:text-[14px] ${suggestionDemandFieldStyles(threadShareSuggestions[activeSuggestionIndex].demand)}`}>
                  {threadShareSuggestions[activeSuggestionIndex].demand}
                </p>

                <div className="mt-4"/>

                <p className="text-[12px] xl:text-[14px] font-[500]">
                  {'Average salary'}
                </p>

                <div className="mt-2"/>

                <p className="text-[12px] xl:text-[14px] text-[#575757]">
                  {threadShareSuggestions[activeSuggestionIndex].avg_salary}
                </p>
              </div>

              <div
                className="hidden sm:flex mt-4 xl:mt-8 before:content-[''] before:w-[1px] before:h-full before:bg-[#e4e0e1] before:flex-1"/>

              <div
                className="sm:w-1/2 pt-2 xl:pt-4 flex flex-col relative sm:after:content-[''] sm:after:w-full sm:after:h-[1.5rem] sm:after:bottom-0 sm:after:bg-gradient-to-b sm:after:from-transparent sm:after:to-[white] sm:after:absolute">
                <div className="mt-2 xl:mt-4"/>

                <div
                  className="hidden sm:block sm:after:content-[''] sm:after:w-full sm:after:h-[1.5rem] sm:after:top-[1rem] xl:after:h-[1.5rem] xl:after:top-[2rem] sm:after:bg-gradient-to-t sm:after:from-transparent sm:after:to-white sm:after:absolute sm:z-[2]"/>

                <Steps values={threadShareSuggestions[activeSuggestionIndex].steps}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThreadShare
