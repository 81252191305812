import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'

type radioInputProps = {
    label: string
    items: string[]
    value: string
    setValue: (_v: string) => void
}

const RadioInput: React.FC<radioInputProps> = ({
  label,
  items,
  value,
  setValue
}): ReactElement => {
  const handleOnChangeEvent = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = event.target.value
    setValue(value)
  }

  return (
    <div className="flex flex-col">
      <p className="text-[12px] lg:text-[14px]">{label}</p>

      <div className="mt-2"/>

      {items.map((item: string, index: number): ReactElement => (
        <div className="flex items-center mb-1 last:mb-0 pl-2" key={index} id="group">
          <input
            type="checkbox"
            name="group"
            className="radio-input"
            value={item}
            checked={value === item}
            onChange={handleOnChangeEvent}
          />

          <div className="ml-2"/>

          <label
            className="text-[12px] lg:text-[14px] text-[#575757]"
          >
            {item}
          </label>
        </div>
      ))}
    </div>
  )
}

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  value: PropTypes.string.isRequired
}

export default RadioInput
