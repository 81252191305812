import React, { ReactElement, useMemo, useState } from 'react'
import { QueryClient, useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query'
import { AuthorizationContext, useAuthorization } from '../context/authorization.context'
import HttpClient from '../services/http/client'
import UserSuggestions from '../components/UserSuggestions'
import { Suggestion as TSuggestion, Suggestion, UpdateSuggestion } from '../types/types'
import { createSuggestionsFormRoute } from '../components/routes'
import { helloEmail } from '../constants/constants'
import { useNotify } from '../context/notify.context'
import Client from '../services/http/client'

type updateSuggestionMutation = { userId: string, threadId: string, suggestionId: string, update: UpdateSuggestion }

const BookmarkedSuggestions = (): ReactElement => {
  const authorizationContext: AuthorizationContext = useAuthorization()
  const { notify } = useNotify()
  const queryClient: QueryClient = useQueryClient()

  const client: HttpClient = useMemo((): Client => new HttpClient(
    process.env.REACT_APP_HTTP_SERVER_ADDRESS ?? 'http://127.0.0.1:8080',
    authorizationContext,
  ), [authorizationContext])

  const {
    data: bookmarkedUserSuggestions,
    isLoading: bookmarkedUserSuggestionsLoading,
    error: bookmarkedUserSuggestionsError
  } = useQuery(
    {
      queryKey: ['bookmarked_suggestions', authorizationContext.value?.user_id],
      queryFn: async (): Promise<Suggestion[]> =>
        await client.userSuggestions(
          authorizationContext.value?.user_id ?? '',
          {
            bookmarked: true
          }
        ),
      enabled: !!authorizationContext.value?.user_id,
    }
  )

  const updateSuggestionMutation: UseMutationResult<
        TSuggestion,
        Error,
        updateSuggestionMutation,
        { prevBookmarkedSuggestions: TSuggestion[] }
    > = useMutation<
        TSuggestion,
        Error,
        updateSuggestionMutation,
        { prevBookmarkedSuggestions: TSuggestion[] }
    >({
      mutationFn: async (args: updateSuggestionMutation): Promise<TSuggestion> =>(
        await client.updateSuggestion(
          args.userId,
          args.threadId,
          args.suggestionId,
          args.update
        )
      ),
      onMutate: async (args: updateSuggestionMutation): Promise<{ prevBookmarkedSuggestions: TSuggestion[] }> => {
        await queryClient.cancelQueries({
          queryKey: ['bookmarked_suggestions', args.userId],
        })

        const prevBookmarkedSuggestions: TSuggestion[] = queryClient.getQueryData<TSuggestion[]>(['bookmarked_suggestions', args.userId]) ?? []

        queryClient.setQueryData(
          ['bookmarked_suggestions', args.userId],
          prevBookmarkedSuggestions.filter((suggestion: TSuggestion) => suggestion.id !== args.suggestionId)
        )

        return { prevBookmarkedSuggestions: prevBookmarkedSuggestions }
      },
      onSuccess: (result: TSuggestion, args: updateSuggestionMutation): void => {
        queryClient.setQueryData(['bookmarked_suggestions', args.userId], (prev: TSuggestion[]) =>
          prev.map((prevSuggestion: TSuggestion): TSuggestion =>
            prevSuggestion.id === args.suggestionId ? result : prevSuggestion,
          ),
        )

        setSelectedSuggestion(result)

        notify('Suggestion updated successfully!', 'success')
      },
      onError: (error: Error, args: updateSuggestionMutation, context): void => {
        if (context?.prevBookmarkedSuggestions) {
          queryClient.setQueryData(['bookmarked_suggestions', args.userId], context.prevBookmarkedSuggestions)
        }

        notify('Failed to update suggestion.', 'error')
      }
    })

  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion>()

  const withBreadcrumb = (children: ReactElement): ReactElement => {
    return (
      <div className="flex flex-col h-full w-full">
        <div className="flex md:items-center text-[11px] md:text-[13px] md:absolute">
          <a
            className="text-[#575757] hover:text-[#0035ff] cursor-pointer transition"
            onClick={() => setSelectedSuggestion(undefined)}
          >
            {'Bookmarked suggestions'}
          </a>

          <div className="ml-1" />

          <p className="text-[#939393]">{'/'}</p>

          <div className="ml-1" />

          {selectedSuggestion ? (
            <p className="text-[#939393]">
              {selectedSuggestion.title}
            </p>
          ): (
            <p className="text-[#939393]">
              {'All'}
            </p>
          )
          }
        </div>

        {children}
      </div>
    )
  }

  if (bookmarkedUserSuggestionsLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <div className="w-[30px] md:w-[40px] rounded-full border-[4px] border-[#e4e0e1] border-r-[#0035ff] spinner"/>

        <div className="mt-4 md:mt-6"/>

        <p className="text-[12px] md:text-[16px]">
          {'Loading...'}
        </p>
      </div>
    )
  }

  if (!bookmarkedUserSuggestions || bookmarkedUserSuggestionsError) {
    return withBreadcrumb(
      <div className="flex flex-col items-center justify-center text-center h-full">
        <p className="text-[14px] md:text-[16px]">{'We are sorry, there was an unknown error with displaying your bookmarked suggestions.'}</p>
        <p className="text-[14px] md:text-[16px]">{'Our team has been already informed and we are working on resolving the issue.'}</p>

        <div className="mt-2 md:mt-4"/>

        <div className="flex">
          <a
            className="text-[14px] md:text-[16px] text-[#0035ff] cursor-pointer"
            href={`mailto:${helloEmail}`}>
            {'You can reach out to us here'}
          </a>
          <p className="text-[14px] md:text-[16px]">{'.'}</p>
        </div>
      </div>
    )
  }

  if (bookmarkedUserSuggestions.length === 0) {
    return withBreadcrumb(
      <div className="flex flex-col justify-center items-center text-center h-full">
        <p className="text-[14px] md:text-[16px]">
          {'Either you haven\'t bookmarked any of your suggestions yet,'}
        </p>

        <p className="text-[14px] md:text-[16px]">
          {'or you have no suggestions.'}
        </p>

        <div className="mt-6 md:mt-8"/>

        <a
          className="flex justify-between items-center p-3 rounded-[10px] bg-[#0035ff] text-white transition hover:bg-[#0029c4]"
          href={createSuggestionsFormRoute}
        >
          <p className="text-[14px] md:text-[16px]">
            {'Create suggestions'}
          </p>
        </a>
      </div>
    )
  }

  const updateSelectedSuggestion = (suggestionId: string, update: UpdateSuggestion): void => updateSuggestionMutation.mutate({
    userId: authorizationContext.value?.user_id ?? '',
    threadId: selectedSuggestion?.thread_id ?? '',
    suggestionId: suggestionId,
    update: update,
  })

  return (
    withBreadcrumb(
      <div className="flex flex-col h-full pt-6 md:pt-14">
        <UserSuggestions
          values={bookmarkedUserSuggestions}
          selected={selectedSuggestion}
          setSelected={(suggestion: Suggestion) => setSelectedSuggestion(suggestion)}
          updateSelected={updateSelectedSuggestion}
        />
      </div>
    )
  )
}

export default BookmarkedSuggestions
