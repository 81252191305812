import React, { ReactElement, useState } from 'react'
import { Suggestion as TSuggestion, UpdateSuggestion } from '../types/types'
import { ReactComponent as BookmarkIcon } from '../media/bookmark-icon.svg'
import PropTypes from 'prop-types'
import Steps from './Steps'
import classNames from 'classnames'
import { suggestionDemandFieldStyles, suggestionMatchRatingColor } from './helpers'

type suggestion = {
    value: TSuggestion
    update: (_id: string, _update: UpdateSuggestion) => void
}

const Suggestion: React.FC<suggestion> = ({ value, update }): ReactElement => {
  const updateBookmarked = (bookmarked: boolean) => update(value.id, {
    bookmarked: bookmarked,
  })

  const [bookmarkButtonHovered, setBookmarkButtonHovered] = useState<boolean>(false)

  const bookmarkButton = (): ReactElement => (
    <div className="relative">
      <button
        onMouseEnter={(): void => setBookmarkButtonHovered(true)}
        onMouseLeave={(): void => setBookmarkButtonHovered(false)}
        onClick={(): void => updateBookmarked(!value.bookmarked)}
      >
        <BookmarkIcon
          className={
            classNames(
              'w-[22px] xl:w-[26px] h-[22px] xl:h-[26px] stroke-[#0035ff] transition-all',
              {
                'fill-[#0035ff] hover:fill-white': value.bookmarked,
                'fill-white hover:fill-[#0035ff]': !value.bookmarked,
              }
            )
          }
        />
      </button>

      {bookmarkButtonHovered && (
        <div
          className="bg-white py-1 px-2 text-center border-[#e4e0e1] border-[1px] rounded-[6px] left-1/2 transform -translate-x-1/2 absolute before:content-[''] before:w-[0] before:h-[0] before:border-r-transparent before:border-r-[8px] before:border-b-white before:border-b-[10px] before:border-l-transparent before:border-l-[8px] before:top-[-10px] before:left-[50%] before:absolute before:translate-x-[-50%] before:z-[1] after:content-[''] after:w-[0] after:h-[0] after:border-r-transparent after:border-r-[9px] after:border-b-[#e4e0e1] after:border-b-[11px] after:border-l-transparent after:border-l-[9px] after:top-[-11px] after:left-[50%] after:absolute after:translate-x-[-50%]"
          style={{
            boxShadow: 'box-shadow: 0 9px 31px #060c240a, 0 2px 5px #060c2408, 0 .5px 1px #060c2405'
          }}
        >
          <p className="text-[12px] font-[400]">
            {value.bookmarked ? 'Remove bookmark' : 'Bookmark'}
          </p>
        </div>
      )}
    </div>
  )

  const matchRating = (value: number): ReactElement => {
    const color: string = suggestionMatchRatingColor(value)

    return (
      <div
        className="relative rounded-full w-[36px] h-[36px] xl:w-[40px] xl:h-[40px] flex justify-center items-center"
        style={{
          background: `conic-gradient(${color} 0% ${value}%, #e4e0e1 ${value}% 100%)`
        }}
      >
        <div className="absolute rounded-full bg-white w-[28px] h-[28px] xl:w-[32px] xl:h-[32px]" />
        <p className="text-[12px] xl:text-[14px] text-[#575757] z-[1]">
          {value}
        </p>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center">
        <p className="text-[28px] xl:text-[34px] font-[500] text-[#0035ff]">{value.title}</p>

        <div className="ml-6 xl:ml-8" />

        {bookmarkButton()}
      </div>

      <div className="mt-6 xl:mt-10"/>

      <div className="flex before:content-[''] before:h-[1px] before:bg-[#e4e0e1] before:flex-1"/>

      <div className="flex flex-col sm:flex-row justify-between sm:overflow-hidden h-full relative">
        <div className="sm:w-1/2 sm:h-full pt-2 xl:pt-4 pr-6 xl:pr-10 sm:overflow-y-scroll">
          <div className="mt-6 xl:mt-10"/>

          {value.match_rating && (
            <>
              <p className="text-[12px] xl:text-[14px] font-[500]">
                {'Match rating'}
              </p>

              <div className="mt-2"/>

              {matchRating(value.match_rating)}

              <div className="mt-4"/>
            </>
          )}

          <p className="text-[12px] xl:text-[14px] font-[500]">
            {'Description'}
          </p>

          <div className="mt-2"/>

          <p className="text-[12px] xl:text-[14px] text-[#575757] text-wrap">
            {value.description}
          </p>

          <div className="mt-4"/>

          <p className="text-[12px] xl:text-[14px] font-[500]">
            {'Skills'}
          </p>

          <div className="mt-2"/>

          <ul className="text-[12px] xl:text-[14px] text-[#575757] list-disc pl-6">
            {value.skills.split(', ').map((skill: string, index: number) => (
              <li
                key={index}
                className="text-[12px] xl:text-[14px] text-[#575757]"
              >
                {skill}
              </li>
            ))}
          </ul>

          <div className="mt-2"/>

          <p className="text-[12px] xl:text-[14px] font-[500]">
            {'Demand'}
          </p>

          <div className="mt-2"/>

          <p className={`text-[12px] xl:text-[14px] ${suggestionDemandFieldStyles(value.demand)}`}>
            {value.demand}
          </p>

          <div className="mt-4"/>

          <p className="text-[12px] xl:text-[14px] font-[500]">
            {'Average salary'}
          </p>

          <div className="mt-2"/>

          <p className="text-[12px] xl:text-[14px] text-[#575757]">
            {value.avg_salary}
          </p>
        </div>

        <div className="hidden sm:flex mt-4 xl:mt-8 before:content-[''] before:w-[1px] before:h-full before:bg-[#e4e0e1] before:flex-1"/>

        <div className="sm:w-1/2 pt-2 xl:pt-4 flex flex-col relative sm:after:content-[''] sm:after:w-full sm:after:h-[1.5rem] sm:after:bottom-0 sm:after:bg-gradient-to-b sm:after:from-transparent sm:after:to-[white] sm:after:absolute">
          <div className="mt-2 xl:mt-4"/>

          <div className="hidden sm:block sm:after:content-[''] sm:after:w-full sm:after:h-[1.5rem] sm:after:top-[1rem] xl:after:h-[1.5rem] xl:after:top-[2rem] sm:after:bg-gradient-to-t sm:after:from-transparent sm:after:to-white sm:after:absolute sm:z-[2]" />

          <Steps values={value.steps}/>
        </div>
      </div>
    </div>
  )}

Suggestion.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    thread_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    skills: PropTypes.string.isRequired,
    avg_salary: PropTypes.string.isRequired,
    demand: PropTypes.string.isRequired,
    bookmarked: PropTypes.bool.isRequired,
    match_rating: PropTypes.number,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        courses: PropTypes.arrayOf(
          PropTypes.shape({
            source: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            headline: PropTypes.string.isRequired,
            rating: PropTypes.number.isRequired,
            subscribers: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired
      }).isRequired
    ).isRequired,
    created_at: PropTypes.string.isRequired
  }).isRequired,
  update: PropTypes.func.isRequired,
}

export default Suggestion
