export const homeRoute: string = '/'

export const signInRoute: string = '/sign-in'
export const signUpRoute: string = '/sign-up'
export const authGoogleCallback: string = '/auth/google/callback'
export const createSuggestionsFormRoute: string = '/s'

const threads: string = '/s'
export const threadSuggestionsRoute: string = threads + '/:threadId'
export const bookmarkedSuggestionsRoute: string = '/bookmarked'

const threadShare: string = '/share'
export const threadShareRoute: string = threadShare + '/:threadShareId'

export const constructThreadShareRoute = (threadShareId: string): string => `${threadShare}/${threadShareId}`
export const constructThreadsSuggestionsRoute = (threadId: string): string => `${threads}/${threadId}`

