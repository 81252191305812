import React, { ReactElement } from 'react'
import { ReactComponent as Logo } from '../media/logo.svg'
import { createSuggestionsFormRoute, homeRoute, signInRoute, signUpRoute } from './routes'
import { useAuthorization } from '../context/authorization.context'

type navigationProps = {
    showFullLogo?: boolean,
}

const Navigation: React.FC<navigationProps> = ({ showFullLogo = false }): ReactElement => {
  const { value } = useAuthorization()

  return (
    <nav className="sticky top-0 bg-white">
      <div className="bg-white border-b-[#e4e0e1] border-b-[1px] flex justify-between items-center px-4 lg:px-0 py-[0.55rem] max-w-[1000px] mx-auto">
        {showFullLogo ? (
          <a href={homeRoute} className="flex items-center justify-center">
            <Logo className="h-[25px] w-[25px]"/>

            <div className="ml-1"/>

            <p className="text-[12px] lg:text-[14px] font-[500]">{'Few Steps Closer'}</p>
          </a>
        ) : (
          <a href={homeRoute}>
            <Logo className="h-[25px] w-[25px]"/>
          </a>
        )}

        {value ? (
          <div className="flex">
            <a
              href={createSuggestionsFormRoute}
              className="rounded-[10px] bg-[#0035ff] text-white text-[12px] lg:text-[14px] text-center hover:bg-[#0029c4] transition flex justify-center align-middle p-2"
            >
              {'My workspace'}
            </a>
          </div>
        ) : (
          <div className="flex">
            <a
              href={signInRoute}
              className="transition flex justify-center items-center align-middle text-[12px] lg:text-[14px] underline"
            >
              {'Sign in'}
            </a>

            <div className="mr-2 lg:mr-4"/>

            <a
              href={signUpRoute}
              className="rounded-[10px] bg-[#0035ff] text-white text-[12px] lg:text-[14px] text-center hover:bg-[#0029c4] transition flex justify-center align-middle p-2"
            >
              {'Sign up'}
            </a>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Navigation
