import React, { ReactElement, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

type textAreaProps = {
    label: string
    value: string
    placeholder?: string
    setValue: (_value: string) => void
}

const TextArea: React.FC<textAreaProps> = ({
  label,
  value,
  placeholder,
  setValue
}): ReactElement => {
  const ref = useRef<HTMLTextAreaElement | null>(null)

  useEffect((): void => {
    if (ref.current) {
      ref.current.style.height = 'auto'
      ref.current.style.height = `${ref.current.scrollHeight}px`
    }
  }, [value])

  return (
    <div className="flex flex-col">
      <label className="text-[12px] lg:text-[14px] pb-1 pl-2">{label}</label>

      <textarea
        ref={ref}
        className=" p-2 max-h-[300px] text-[12px] lg:text-[14px] border-[1px] border-[#e4e0e1] rounded-[10px] resize-none transition focus:outline-[#0035ff]"
        rows={5}
        placeholder={placeholder && `This space is for any last details or insights you’d like to include that didn’t quite fit elsewhere.\n\neg. ${placeholder}`}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setValue(event.target.value)}
      />
    </div>
  )
}

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default TextArea
