import axios, { AxiosInstance, AxiosResponse } from 'axios'
import {
  ThreadWithSuggestions,
  Suggestion,
  Thread,
  UpdateSuggestion,
  UpdateThread, Limits, CreateSuggestions
} from '../../types/types'
import { SignInRequest, SignInResult, SignUpRequest, SignUpResult } from './types'
import { axiosResponseInterceptorWithRefresh } from './helpers'
import { STATUS_NO_CONTENT, STATUS_OK } from './constants'
import { AuthorizationContext } from '../../context/authorization.context'

class Client {
  private client: AxiosInstance
  private authorization: AuthorizationContext

  constructor(address: string, authorizationContext: AuthorizationContext) {
    const validateStatus = (status: number): boolean => status == STATUS_OK || status == STATUS_NO_CONTENT

    const client = axios.create({
      baseURL: address,
      validateStatus: validateStatus,
    })

    client.interceptors.response.use(
      (response: AxiosResponse) => {
        return response
      },
      (error) => axiosResponseInterceptorWithRefresh(error, address, client, authorizationContext)
    )

    this.client = client
    this.authorization = authorizationContext
  }

  async auth(): Promise<void> {
    await this.client.post('/auth', {}, {
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`
      },
    })
  }

  async signUp(signUp: SignUpRequest): Promise<SignUpResult> {
    const result: AxiosResponse = await this.client.post('/sign-up', signUp, {
      headers: {
        'Content-Type': 'application/json',
      }
    })

    return result.data as SignUpResult
  }

  async signIn(signIn: SignInRequest): Promise<SignInResult> {
    const result: AxiosResponse = await this.client.post('/sign-in', signIn, {
      headers: {
        'Content-Type': 'application/json',
      }
    })

    return result.data as SignInResult
  }

  async signGoogle(): Promise<string> {
    const result: AxiosResponse = await this.client.post('/auth/google', {})
    return result.data as string
  }

  async userThreads(userId: string): Promise<Thread[]> {
    const result: AxiosResponse = await this.client.get(`/users/${userId}/threads`,{
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`
      },
    })

    return result.data as Thread[]
  }

  async userSuggestions(userId: string, options?: { bookmarked?: boolean }): Promise<Suggestion[]> {
    let url: string = `/users/${userId}/suggestions`

    if (options) {
      url += '?'
    }

    if (options?.bookmarked !== undefined) {
      url += `bookmarked=${options.bookmarked}`
    }

    const result: AxiosResponse = await this.client.get(url, {
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`
      },
    })

    return result.data as Suggestion[]
  }

  async userLimits(userId: string): Promise<Limits> {
    let url: string = `/users/${userId}/limits`

    const result: AxiosResponse = await this.client.get(url, {
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`
      },
    })

    return result.data as Limits
  }

  async createThreadWithSuggestions(userId: string, create: CreateSuggestions): Promise<ThreadWithSuggestions> {
    const result: AxiosResponse = await this.client.post(`/users/${userId}/threads`, JSON.stringify(create),{
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`,
        'Content-Type': 'application/json'
      },
    })

    return result.data as ThreadWithSuggestions
  }

  async updateThread(userId: string, threadId: string, update: UpdateThread): Promise<Thread> {
    const result: AxiosResponse = await this.client.patch(`/users/${userId}/threads/${threadId}`, JSON.stringify(update),{
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`,
        'Content-Type': 'application/json'
      },
    })

    return result.data as Thread
  }

  async deleteThread(userId: string, threadId: string): Promise<void> {
    await this.client.delete(`/users/${userId}/threads/${threadId}`,{
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`
      },
    })
  }

  async createThreadShare(userId: string, threadId: string): Promise<string> {
    const result: AxiosResponse = await this.client.post(`/users/${userId}/threads/${threadId}/share`, {}, {
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`
      },
    })

    return (result.data as { id: string }).id
  }

  async threadShareSuggestions(threadShareId: string): Promise<Suggestion[]> {
    const result : AxiosResponse = await this.client.get(`/share/${threadShareId}`)

    return result.data as Suggestion[]
  }

  async threadSuggestions(userId: string, threadId: string): Promise<Suggestion[]> {
    const result: AxiosResponse = await this.client.get(`/users/${userId}/threads/${threadId}/suggestions`,{
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`
      },
    })

    return result.data as Suggestion[]
  }

  async updateSuggestion(userId: string, threadId: string, suggestionId: string, update: UpdateSuggestion): Promise<Suggestion> {
    const result: AxiosResponse = await this.client.patch(`/users/${userId}/threads/${threadId}/suggestions/${suggestionId}`, JSON.stringify(update),{
      headers: {
        'Authorization': `Bearer ${this.authorization.value?.auth_token}`,
        'Content-Type': 'application/json'
      },
    })

    return result.data as Suggestion
  }
}

export default Client
