import React, { ReactElement } from 'react'
import { signUpRoute } from './routes'

const CTA = (): ReactElement => (
  <section className="flex flex-col justify-center items-center px-10 md:px-16 lg:px-0">
    <div className="flex flex-col justify-center items-center text-center">
      <p className="text-[28px] lg:text-[38px] font-[500] leading-[32px] tracking-tight">
        {'Ready to make the next step?'}
      </p>

      <div className="mt-10"/>

      <a
        href={signUpRoute}
        className="rounded-[10px] bg-[#0035ff] text-white text-[14px] lg:text-[16px] p-4 text-center hover:bg-[#0029c4] transition"
      >
        {'Find your career'}
      </a>
    </div>
  </section>
)

export default CTA
