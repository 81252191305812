import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import classNames from 'classnames'
import { ReactComponent as HamburgerLeftIcon } from '../media/hamburger-left-icon.svg'
import { ReactComponent as Logo } from '../media/logo.svg'
import { homeRoute } from './routes'

const Layout = (): ReactElement => {
  const [isSidebarOpened, setIsSidebarOpened] = useState<boolean>(false)

  const sidebarWrapperRef = useRef<HTMLDivElement | null>(null)
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect((): () => void => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        sidebarRef.current &&
                sidebarWrapperRef.current &&
                (
                  sidebarWrapperRef.current.contains(event.target as Node) &&
                    !sidebarRef.current.contains(event.target as Node)
                )
      ) {
        setIsSidebarOpened((prev: boolean): boolean => !prev)
      }
    }

    const handleResize = (): void => setIsSidebarOpened(false)

    document.addEventListener('click', handleClickOutside)
    window.addEventListener('resize', handleResize)

    return () => {
      document.removeEventListener('click', handleClickOutside)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const scrollToTop = (): void => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTo({ top: 0 })
    }
  }

  const topbar = (): ReactElement => (
    <div className="md:hidden p-4 flex items-center sticky top-0 z-[1] bg-white border-b-[#e4e0e1] border-b-[1px]">
      <HamburgerLeftIcon
        className="w-[28px] fill-black stroke-black cursor-pointer"
        onClick={(): void => setIsSidebarOpened((prev: boolean): boolean => !prev)}
      />

      <a className="absolute left-1/2 transform -translate-x-1/2" href={homeRoute}>
        <Logo className="h-[25px] w-[25px]"/>
      </a>
    </div>
  )

  return (
    <div className="flex flex-col md:flex-row h-[100svh] w-full min-h-screen bg-white md:bg-[#f8f8f8]">
      {topbar()}

      <div
        ref={sidebarWrapperRef}
        className={
          classNames(
            'top-0 flex w-full h-[100svh] md:relative bg-[#00000090] z-[5] fixed',
            { 'hidden': !isSidebarOpened }
          )
        }
      >
        <div
          ref={sidebarRef}
          className="flex h-full bg-white pr-4"
        >
          <Sidebar/>
        </div>
      </div>

      <div className="hidden bg-[#f8f8f8] md:flex">
        <Sidebar/>
      </div>

      <div ref={contentRef} className="flex h-[100svh] justify-center items-center overflow-y-scroll md:h-auto p-8 pt-4 md:m-4 md:py-10 md:w-full xl:px-16 md:border-[1px] md:border-[#e4e0e1] md:rounded-[10px] md:bg-white md:shadow-[0_9px_31px_#060c240a,0_2px_5px_#060c2408,0_0.5px_1px_#060c2405]">
        <Outlet context={{ scrollToTop }} />
      </div>
    </div>
  )
}

export default Layout
