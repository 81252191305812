import React, { ReactElement } from 'react'
import Navigation from '../components/Navigation'
import Hero from '../components/Hero'
import Product from '../components/Product'
import CTA from '../components/CTA'
import Footer from '../components/Footer'

const Home = (): ReactElement => (
  <div className="flex flex-col w-full">
    <Navigation />

    <main className="w-auto max-w-[1000px] lg:mx-auto block static">
      <Hero/>

      <Product/>

      <div className="mt-20 lg:mt-32"/>

      <CTA/>
    </main>

    <div className="mt-20 lg:mt-32"/>

    <Footer />
  </div>
)

export default Home
