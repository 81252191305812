import React, { ReactElement, useMemo } from 'react'
import { AuthorizationContext, useAuthorization } from '../context/authorization.context'
import HttpClient from '../services/http/client'
import Thread from './Thread'
import { Thread as TThread, UpdateThread } from '../types/types'
import { QueryClient, useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { constructThreadShareRoute, createSuggestionsFormRoute } from './routes'
import { usePopper } from '../context/popper.context'
import { useNotify } from '../context/notify.context'
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import Client from '../services/http/client'
import { constructBaseURL } from './helpers'

type shareThreadMutation = { userId: string, threadId: string }
type updateThreadMutation = { userId: string, threadId: string, update: UpdateThread }
type deleteThreadMutation = { userId: string, threadId: string }

type threadsProps = {
  values: TThread[]
}

const Threads: React.FC<threadsProps> = ({ values }): ReactElement => {
  const authorizationContext: AuthorizationContext = useAuthorization()
  const { hidePopper } = usePopper()
  const { notify } = useNotify()
  const navigate: NavigateFunction = useNavigate()
  const location: Location = useLocation()
  const queryClient: QueryClient = useQueryClient()

  const client: HttpClient = useMemo((): Client => new HttpClient(
    process.env.REACT_APP_HTTP_SERVER_ADDRESS ?? 'http://127.0.0.1:8080',
    authorizationContext,
  ), [authorizationContext])

  const updateThreadMutation: UseMutationResult<
      TThread,
      Error,
      updateThreadMutation,
      { prevThreads: TThread[] }
  > = useMutation<
      TThread,
      Error,
      updateThreadMutation,
      { prevThreads: TThread[] }
  >({
    mutationFn: async (args: updateThreadMutation): Promise<TThread> =>(
      await client.updateThread(
        args.userId,
        args.threadId,
        args.update
      )
    ),
    onMutate: async (args: updateThreadMutation): Promise<{ prevThreads: TThread[] }> => {
      await queryClient.cancelQueries({
        queryKey: ['threads', args.userId]
      })

      const prevThreads: TThread[] = queryClient.getQueryData<TThread[]>(['threads', args.userId]) ?? []

      queryClient.setQueryData(
        ['threads', args.userId],
        prevThreads.map((thread: TThread) =>
          thread.id === args.threadId ? { ...thread, ...args.update } : thread
        )
      )

      return { prevThreads: prevThreads }
    },
    onSuccess: (result: TThread, args: updateThreadMutation): void => {
      queryClient.setQueryData(['threads', args.userId], (prev: TThread[]) =>
        prev.map((prevThread: TThread): TThread =>
          prevThread.id === args.threadId ? result : prevThread,
        ),
      )

      notify('Suggestions updated successfully!', 'success')
      hidePopper()
    },
    onError: (_error: Error, args: updateThreadMutation, context): void => {
      if (context?.prevThreads) {
        queryClient.setQueryData(['threads', args.userId], context.prevThreads)
      }

      notify('Failed to update suggestions.', 'error')
    }
  })

  const shareThreadMutation: UseMutationResult<string, Error, shareThreadMutation> = useMutation<string, Error, shareThreadMutation>({
    mutationFn: async (args: shareThreadMutation): Promise<string> => {
      const result: string = await client.createThreadShare(args.userId, args.threadId)
      return constructBaseURL() + constructThreadShareRoute(result)
    },
    onError: (): void => {
      notify('Failed to create a shareable link.', 'error')
    }
  })

  const deleteThreadMutation: UseMutationResult<deleteThreadMutation, Error, deleteThreadMutation> = useMutation<deleteThreadMutation, Error, deleteThreadMutation>({
    mutationFn: async (args: deleteThreadMutation): Promise<deleteThreadMutation> =>
      client.deleteThread(args.userId, args.threadId).then(() => args),
    onSuccess: async (args: deleteThreadMutation): Promise<void> => {
      queryClient.setQueryData(['threads', args.userId], (prev: TThread[] | undefined) =>
        (prev ?? []).filter(
          (thread: TThread): boolean => thread.id !== args.threadId
        )
      )

      if (location.pathname.includes(args.threadId)) {
        navigate(createSuggestionsFormRoute)
      }

      hidePopper()
      notify('Suggestions deleted successfully!', 'success')
    },
    onError: (): void => {
      notify('Failed to delete suggestions.', 'error')
    }
  })

  const now: Date = new Date()
  const startOfToday: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const startOfYesterday: Date = new Date(startOfToday)
  const startOfLast7Days: Date = new Date(startOfToday)

  startOfYesterday.setDate(startOfToday.getDate() - 1)
  startOfLast7Days.setDate(startOfToday.getDate() - 7)

  const todayThreads: TThread[] = []
  const yesterdayThreads: TThread[] = []
  const last7DaysThreads: TThread[] = []
  const olderThreads: TThread[] = []

  for (let thread of values) {
    const createdAt: Date = new Date(thread.created_at)

    switch (true) {
    case createdAt >= startOfToday:
      todayThreads.push(thread)
      continue
    case createdAt >= startOfYesterday && createdAt < startOfToday:
      yesterdayThreads.push(thread)
      continue
    case createdAt >= startOfLast7Days && createdAt < startOfYesterday:
      last7DaysThreads.push(thread)
      continue
    default:
      olderThreads.push(thread)
    }
  }

  const shareThread = async (threadId: string): Promise<string> => await shareThreadMutation.mutateAsync({
    userId: authorizationContext.value?.user_id ?? '',
    threadId: threadId,
  })

  const updateThread = (threadId: string, update: UpdateThread) => updateThreadMutation.mutate({
    userId: authorizationContext.value?.user_id ?? '',
    threadId: threadId,
    update: update,
  })

  const deleteThread = (threadId: string): void => deleteThreadMutation.mutate({
    userId: authorizationContext.value?.user_id ?? '',
    threadId: threadId,
  })

  const timePeriodThreadsElement = (header: string, threads: TThread[]) => (
    <div className="w-full max-w-full relative">
      <p className="w-full pl-2 pt-1 top-0 text-[12px] lg:text-[14px] font-[500] sticky z-10 bg-white md:bg-[#f8f8f8]">
        {header}
      </p>

      <div className="mt-1"/>

      {threads.map((thread: TThread, index: number): ReactElement => (
        <Thread
          key={index}
          value={thread}
          shareThread={shareThread}
          updateThread={updateThread}
          deleteThread={deleteThread}
        />
      ))}
    </div>
  )

  return (
    <div className="flex flex-col items-start overflow-y-scroll w-full without-scrollbar">
      {todayThreads.length >= 1 && timePeriodThreadsElement('Today', todayThreads)}
      {yesterdayThreads.length >= 1 && timePeriodThreadsElement('Yesterday', yesterdayThreads)}
      {last7DaysThreads.length >= 1 && timePeriodThreadsElement('Previous 7 days', last7DaysThreads)}
      {olderThreads.length >= 1 && timePeriodThreadsElement('Older', olderThreads)}
    </div>
  )
}

export default Threads
