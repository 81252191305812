import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

type scaleInputProps = {
    statement: string
    value: number
    changeValue: (_value: number) => void
    disagreeLabel?: string
    agreeLabel?: string
}

const ScaleInput: React.FC<scaleInputProps> = ({
  statement,
  value,
  changeValue,
  disagreeLabel = 'Disagree',
  agreeLabel = 'Agree'
}): ReactElement => {
  const circleSize = (index: number): string =>  {
    switch (index) {
    case 0:
      return 'w-[22px] lg:w-[28px] h-[22px] lg:h-[28px]'
    case 4:
      return 'w-[22px] lg:w-[28px] h-[22px] lg:h-[28px]'
    case 1:
      return 'w-[16px] lg:w-[22px] h-[16px] lg:h-[22px]'
    case 3:
      return 'w-[16px] lg:w-[22px] h-[16px] lg:h-[22px]'
    default:
      return 'w-[12px] lg:w-[14px] h-[12px] lg:h-[14px]'
    }
  }

  return (
    <div className="flex flex-col">
      <p className="text-[12px] lg:text-[14px] text-center text-[#575757]">{statement}</p>

      <div className="mt-4"/>

      <div className="flex flex-col items-center w-full px-2">
        <div className="flex justify-evenly w-full items-center">
          <p className="hidden sm:flex text-[10px] lg:text-[12px] text-[#575757]">{disagreeLabel}</p>

          <div className="flex justify-between items-center w-full lg:w-[375px] max-w-[375px] m-auto mx-0 sm:mx-4">
            {[1, 2, 3, 4, 5].map((v, index: number): ReactElement => (
              <div
                key={index}
                className={
                  classNames(
                    'flex items-center border-[1px] border-[#0035ff] rounded-full cursor-pointer',
                    circleSize(index),
                    {
                      'bg-[#0035ff] shadow-[inset_0_0_0_2px_#ffffff]': v === value
                    }
                  )
                }
                onClick={(): void => changeValue(v)}
              />
            ))}
          </div>

          <p className="hidden sm:flex text-[10px] lg:text-[12px] text-[#575757]">{agreeLabel}</p>
        </div>


        <div className="sm:hidden w-full">
          <div className="mt-2"/>

          <div className="flex flex-row justify-between">
            <p className="text-[10px] lg:text-[12px] text-[#575757]">{disagreeLabel}</p>
            <p className="text-[10px] lg:text-[12px] text-[#575757]">{agreeLabel}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

ScaleInput.propTypes = {
  statement: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

export default ScaleInput
