import React, { ReactElement, useMemo } from 'react'
import { ReactComponent as Logo } from '../media/logo.svg'
import { ReactComponent as AddIcon } from '../media/add-icon.svg'
import { AuthorizationContext, useAuthorization } from '../context/authorization.context'
import { ReactComponent as SignOutIcon } from '../media/sign-out-icon.svg'
import { createSuggestionsFormRoute, homeRoute } from './routes'
import Threads from './Threads'
import SidebarNavigation from './SidebarNavigation'
import { useQuery } from '@tanstack/react-query'
import { Thread as TThread } from '../types/types'
import HttpClient from '../services/http/client'
import Client from '../services/http/client'

const Sidebar = (): ReactElement => {
  const authorizationContext: AuthorizationContext = useAuthorization()

  const client: HttpClient = useMemo((): Client => new HttpClient(
    process.env.REACT_APP_HTTP_SERVER_ADDRESS ?? 'http://127.0.0.1:8080',
    authorizationContext,
  ), [authorizationContext])

  const {
    data: threads,
    isLoading: threadsLoading
  } = useQuery(
    {
      queryKey: ['threads', authorizationContext.value?.user_id],
      queryFn: async (): Promise<TThread[]> =>
        client.userThreads(authorizationContext.value?.user_id ?? ''),
    }
  )

  return (
    <div className="flex flex-col justify-between shrink-0 w-[215px] lg:w-[275px] lg:max-w-[275px] pl-4 py-4 relative">
      <div className="flex flex-col items-center overflow-hidden w-full h-full">
        <div className="flex justify-between w-full">
          <a className="flex items-center cursor-pointer" href={homeRoute}>
            <Logo className="h-[25px] w-[25px]"/>

            <div className="ml-1"/>

            <p className="text-[12px] lg:text-[14px] font-[500]">{'Few Steps Closer'}</p>
          </a>

          <div className="flex items-center">
            <p className="text-[11px]">{'v0.1'}</p>
          </div>
        </div>

        <div className="mt-6 md:mt-8"/>

        <a
          href={createSuggestionsFormRoute}
          className="flex items-center justify-center w-full p-2 border-[1px] border-[#e4e0e1] rounded-[8px] bg-white hover:border-[#0035ff] transition cursor-pointer"
        >
          <AddIcon className="w-[15px] h-[15px]"/>

          <div className="ml-2"/>

          <p className="text-[12px] lg:text-[14px]">{'New suggestions'}</p>
        </a>

        <div className="mt-6 md:mt-8"/>

        <SidebarNavigation/>

        <div className="mt-6 md:mt-8 h-[2px]"/>

        {threadsLoading ? (
          <div className="flex h-full justify-center items-center">
            <div className="inline-block w-[20px] h-[20px] border-[4px] border-dotted border-[#575757] rounded-full"
              style={{
                animation: 'loading-spinner-rotation 1.3s linear infinite'
              }}
            />
          </div>
        ) : (
          <Threads values={threads ?? []}/>
        )}
      </div>

      <div className="pt-2 z-10">
        <div className="flex before:content-[''] before:w-full before:h-[1px] before:bg-[#e4e0e1]"/>

        <div className="mt-2"/>

        <button
          className="flex items-center justify-center w-full p-2 border-[1px] border-[#e4e0e1] rounded-[10px] hover:border-[#0035ff] transition"
          onClick={(): void => authorizationContext.signOut()}
        >
          <SignOutIcon className="w-[14px] h-[14px]"/>

          <div className="ml-1"/>

          <p className="text-[12px] lg:text-[14px]">{'Sign out'}</p>
        </button>
      </div>
    </div>
  )
}

export default Sidebar
