export const copyToClipboard = async (shareURL: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(shareURL ?? '')
  } catch (err) {
    fallbackCopyTextToClipboard(shareURL ?? '')
  }
}

const fallbackCopyTextToClipboard = (text: string) => {
  const input: HTMLInputElement = document.createElement('input')
  input.setAttribute('readonly', 'true')
  input.setAttribute('contenteditable', 'true')
  input.value = text
  document.body.appendChild(input)
  input.style.position = 'absolute'
  input.style.left = '-9999px'
  input.focus()
  input.select()

  const range: Range = document.createRange()
  range.selectNodeContents(input)

  const selection: Selection | null = window.getSelection()
  selection?.removeAllRanges()
  selection?.addRange(range)

  input.setSelectionRange(0, input.value.length)

  const successful: boolean = document.execCommand('copy')
  if (!successful) {
    throw new Error('Could not copy to clipboard')
  }

  document.body.removeChild(input)
}
