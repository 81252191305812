import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'

type sliderInputProps = {
    statement: string
    value: number
    changeValue: (_value: number) => void
}

const SliderInput: React.FC<sliderInputProps> = ({
  statement,
  value,
  changeValue
}): ReactElement => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const stringValue = event.target.value
    const numberValue: number = Number(stringValue)

    changeValue(numberValue)
  }

  return (
    <div className="flex flex-col px-2">
      <p className="text-[12px] lg:text-[14px] text-center text-[#575757]">{statement}</p>

      <div className="mt-4"/>

      <div className="flex flex-col justify-center items-center">
        <p className="text-[12px] lg:text-[13px] text-[#575757]">{value}</p>

        <div className="flex items-center w-full">
          <p className="text-[12px] lg:text-[13px] text-[#575757]">{'0'}</p>

          <div className="ml-2"/>

          <input
            className="w-full slider"
            type="range"
            min={0}
            max={10}
            name="group"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
          />

          <div className="ml-2"/>

          <p className="text-[12px] lg:text-[13px] text-[#575757]">{'10'}</p>
        </div>
      </div>
    </div>
  )
}

SliderInput.propTypes = {
  statement: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

export default SliderInput
